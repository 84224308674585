import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import appGoogleTracking from "../../modules/GoogleTracking";

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

class footersocial extends Component {
  render(){
    const{t} = this.props;
    let pinterestLink = null;
    if((window.URL_PREFIX === "de-de") | (window.URL_PREFIX === "de-at")) {
      pinterestLink = (
        <a
      className="js-event-cta"
      href={t("footer.socialSection.pinterestUrl")}
      target="_blank"
      rel="noopener noreferrer"
      data-event="social-click"
      data-category="Social Action"
      data-action="Social share"
      data-label="Pinterest"
      //onClick={appGoogleTracking.processEventCTA}
    >
      <span className="icon-pinterest"></span>
    </a>
      );
    }
    return (
  <div className="footer__social">
    <a
      className="js-event-cta"
      href={t("footer.socialSection.instagramUrl")}
      target="_blank"
      rel="noopener noreferrer"
      data-event="social-click"
      data-category="Social Action"
      data-action="Social share"
      data-label="Instagram"
      //onClick={appGoogleTracking.processEventCTA}
    >
      <span className="icon-instagram"></span>
    </a>
    <a
      className="js-event-cta"
      href={t("footer.socialSection.facebookUrl")}
      target="_blank"
      rel="noopener noreferrer"
      data-event="social-click"
      data-category="Social Action"
      data-action="Social share"
      data-label="Facebook"
      //onClick={appGoogleTracking.processEventCTA}
    >
      <span className="icon-facebook"></span>
    </a>
    <a
      className="js-event-cta"
      href={t("footer.socialSection.youtubeUrl")}
      target="_blank"
      rel="noopener noreferrer"
      data-event="social-click"
      data-category="Social Action"
      data-action="Social share"
      data-label="Youtube"
      //onClick={appGoogleTracking.processEventCTA}
    >
      <span className="icon-youtube">
        <span className="icon-boundary"></span>
        <span className="icon-play"></span>
      </span>
    </a>
    {pinterestLink}
  </div>
    );
}
}

export default withTranslation()(footersocial);
