import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import appGoogleTracking from '../../modules/GoogleTracking'

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

class footerlinks extends Component {
  render() {
    const { t } = this.props
    let footerLinks = null

    if ((window.URL_PREFIX === 'de-de') | (window.URL_PREFIX === 'de-at')) {
      footerLinks = (
        <>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.termsOfUseUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title={t('footer.linkSection.termsOfUse')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.termsOfUse')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.termsOfUse')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.dataProtectionUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title={t('footer.linkSection.dataProtection')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.dataProtection')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.dataProtection')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.impressumUrl')}
              title={t('footer.linkSection.impressum')}
              target="_blank"
              rel="noopener noreferrer"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.impressum')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.impressum')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.usageOnlineUrl')}
              rel="noopener noreferrer"
              title={t('footer.linkSection.usageOnline')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.usageOnline')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.usageOnline')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.mondelezUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title={t('footer.linkSection.mondelez')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.llinkSection.mondelez')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.mondelez')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.cookieUrl')}
              title={t('footer.linkSection.cookie')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.cookie')}
              target="_blank"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.cookie')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.contactUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title={t('footer.linkSection.contact')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.contact')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.contact')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.faqUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title={t('footer.linkSection.faq')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.faq')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.faq')}
            </a>
          </span>
        </>
      )
    } else if (window.URL_PREFIX === 'fr-fr') {
      footerLinks = (
        <>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.termsOfUseUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title={t('footer.linkSection.termsOfUse')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.termsOfUse')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.termsOfUse')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.dataProtectionUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title={t('footer.linkSection.dataProtection')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.dataProtection')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.dataProtection')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.impressumUrl')}
              title={t('footer.linkSection.impressum')}
              target="_blank"
              rel="noopener noreferrer"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.impressum')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.impressum')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.usageOnlineUrl')}
              rel="noopener noreferrer"
              title={t('footer.linkSection.usageOnline')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.usageOnline')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.usageOnline')}
            </a>
          </span>
          <span className="legal__link">
            <a
              className="js-event-cta"
              href={t('footer.linkSection.mondelezUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title={t('footer.linkSection.mondelez')}
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label={t('footer.linkSection.mondelez')}
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.linkSection.mondelez')}
            </a>
          </span>
        </>
      )
    }
    return <div className="footer__legal">{footerLinks}</div>
  }
}

export default withTranslation()(footerlinks)
