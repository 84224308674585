import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import { mainLoaderToggle } from "../../modules/Helpers";
import appGoogleTracking from "../../modules/GoogleTracking";
import GLOBAL_CONFIG, { GetRoute } from '../../config/Config'
import Banner from "../../components/Oreo/Banner";

class ThankYou extends Component {
  componentDidMount() {
/*     window.dataLayer.push({
      event: "virtual-page",
      path: "/danke",
    }); */
    let banner = document.querySelector(".banner-container");

    if (!!banner) {
      banner.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    mainLoaderToggle("hide");
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    const { t } = this.props;
    let stripBanner = null;
    
    let Routes = GetRoute(window.URL_PREFIX); 

    if ((window.URL_PREFIX === "de-de") | (window.URL_PREFIX === "de-at")) {
      stripBanner = (
        <div className="strip__banner">
          <div className="section1">
            <p className="linkText">{parse(t("page.holding.linkText"))}</p>
          </div>
          <div className="section2">
            <p
              className="buttonText"
              onClick={() => {
                appGoogleTracking.googleTag(
                  "click_action",
                  "subscribe",
                  "newsletter_subscribe_button"
                );
              }}
            >
              {parse(t("page.holding.buttonText"))}
            </p>
          </div>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>{t("meta.title")}</title>
          <meta name="description" content={t("meta.description")} />
          <body className={window.URL_PREFIX + " thank-you"} />
        </Helmet>
        <div className="thank-you__wrapper oreo-thank-you">
          <Banner
            DesktopImage={t("page.thank-you.oreo.banner.desktop")}
            MobileImage={t("page.thank-you.oreo.banner.mobile")}
            AltText={t("page.thank-you.oreo.banner.alt")}
          />

          <div className="main__content">
            <p className="text__heading">{t('page.thank-you.oreo.title')}</p>
            <p className="text__desc">{parse(t('page.thank-you.oreo.desc'))}</p>

            <div className="product-wrapper">
              <div className="item">
                <picture>
                  <source srcSet={t("page.holding.oreo.product.1")} media="(min-width: 481px)" />
                  <source srcSet={t("page.holding.oreo.product.1")} media="(min-width: 200px)" />
                  <img
                    className="product__img"
                    src={t("page.holding.oreo.product.1")}
                    alt="product"
                  />
                </picture>
                <p><a href={`/${window.URL_PREFIX}${Routes.termsAndConditions}#prize`} target='_blank' rel="noreferrer">{t("page.holding.oreo.product.product-similar")}</a></p>
              </div>
              <div className="item">
                <picture>
                  <source srcSet={t("page.holding.oreo.product.2")} media="(min-width: 481px)" />
                  <source srcSet={t("page.holding.oreo.product.2")} media="(min-width: 200px)" />
                  <img
                    className="product__img"
                    src={t("page.holding.oreo.product.2")}
                    alt="product"
                  />
                </picture>
                <p><a href={`/${window.URL_PREFIX}${Routes.termsAndConditions}#prize`} target='_blank' rel="noreferrer">{t("page.holding.oreo.product.product-similar")}</a></p>
              </div>
              <div className="item">
              <picture>
                  <source srcSet={t("page.holding.oreo.product.3")} media="(min-width: 481px)" />
                  <source srcSet="/resources/images/oreo/holding/product_3-.png" media="(min-width: 200px)" />
                  <img
                    className="product__img"
                    src={t("page.holding.oreo.product.3")}
                    alt="product"
                  />
                </picture>
                <p><a href={`/${window.URL_PREFIX}${Routes.termsAndConditions}#prize`} target='_blank' rel="noreferrer">{t("page.holding.oreo.product.product-similar")}</a></p>
              </div>
            </div>

            <div className="product-wrapper-mobile-second">
              <div className="product-content">
                <img
                className="product__img"
                src="/resources/images/oreo/thank-you/muffin.png"
                alt="product"
                />
                <div className="content-wrapper">
                  <div className="product-content__title">
                    OREO Muffin
                  </div>
                  <div className="product-content__desc">
                  {t("page.holding.oreo.product.text-2")}
                  </div>
                </div>
              </div>
              <div className="product-content">
                <img
                className="product__img"
                src="/resources/images/oreo/thank-you/donut.png"
                alt="product"
                />
                <div className="content-wrapper">
                  <div className="product-content__title">
                    OREO Donut
                  </div>
                  <div className="product-content__desc">
                    {t("page.holding.oreo.product.text-1")}
                  </div>
                </div>
              </div>
            </div>
            <a 
              href={t("page.holding.oreo.cta-link")}
              target="_blank" 
              className="oreo-button" 
              data-event="discover_oreo"
              data-category="Confirmation Page"
              data-action="Go to Page"
              data-label="OREO_CP_OTHER"
              onClick={appGoogleTracking.processEventCTA}
              rel="noreferrer"
              >
              <button className="oreo-button-blue">
              {t("page.holding.oreo.cta-text")}
              </button>
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ThankYou);
