import React, { Component } from "react";
import { scrollToElementwithTimeout } from "../../../modules/Helpers";

export class Nl extends Component {
  componentDidMount() {
    scrollToElementwithTimeout();
  }
  Table() {
    return (
      <>

        <p className="content__text borderline">1x Pakket 1
        </p>
        <p className="content__text borderin">
          <b>Ultieme meeslepende set met Arc, Sub, 2 Era 300</b>	 <br />
          Waarde in Euro: € 2.896,00 <br />
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 50" Neo QLED 4K QN90C</b>	 <br />
          Waarde in Euro: € 1.999,00
        </p>

        <p className="content__text borderline">1x Pakket 2
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 75" Neo QLED 8K QN800B</b>	 <br />
          Waarde in Euro: € 3.089,00 <br />
        </p>
        <p className="content__text borderin">
          <b>Microsoft, XBox Series X, geen game</b>	 <br />
          Waarde in Euro: € 499,90
        </p>
        <p className="content__text borderin">
          <b>Xbox Elite draadloze controller Series 2, 2 stukken</b>	 <br />
          Waarde in Euro: € 290,00
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, zwart</b>	 <br />
          Waarde in Euro: € 999,00
        </p>

        <p className="content__text borderline">1x Pakket 3
        </p>
        <p className="content__text borderin">
          <b>iPad Pro 12,9'', Spacegrijs, 512 GB, Wi-Fi,
            geen inscriptie, geen pencil, geen toetsenbord, geen Apple Care+</b>	 <br />
          Waarde in Euro: € 1.849,45<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, ohne Apple Care+</b>	 <br />
          Waarde in Euro: € 998,75
        </p>
        <p className="content__text borderin">
          <b>Canon EOS R7-systeemcamera en RF-S 18-150mm F3.5-6.3 IS STM-lens</b>	 <br />
          Waarde in Euro: € 1.999,00
        </p>

        <p className="content__text borderline">2x Pakket 4
        </p>
        <p className="content__text borderin">
          <b>Canon EOS R7-systeemcamera en RF-S 18-150mm F3.5-6.3 IS STM-lens</b>	 <br />
          Waarde in Euro: € 1.999,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, geen Apple Care+</b>	 <br />
          Waarde in Euro: € 998,75
        </p>

        <p className="content__text borderline">2x Pakket 5
        </p>
        <p className="content__text borderin">
          <b>iPad Pro 12,9'', Spacegrijs, 512 GB, Wi-Fi,
            geen inscriptie, geen pencil, geen toetsenbord, geen Apple Care+</b>	 <br />
          Waarde in Euro: € 1.849,45<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, geen Apple Care+</b>	 <br />
          Waarde in Euro: € 998,75
        </p>

        <p className="content__text borderline">2x Pakket 6
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, zwart</b>	 <br />
          Waarde in Euro: € 999,00
        </p>


        <p className="content__text borderline">1x Pakket 7
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, geen Apple Care+</b>	 <br />
          Waarde in Euro: € 998,75
        </p>

        <p className="content__text borderline">2x Pakket 8
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, kast aluminium, middernacht, Sportbandje, 41mm, connectiviteit GPS , geen Apple Care+</b>	 <br />
          Waarde in Euro: € 499,00
        </p>
        <p className="content__text borderin">
          <b>Air Pods Pro (2e generatie)</b>	 <br />
          Waarde in Euro: € 299,00 
        </p>

        <p className="content__text borderline">3x Pakket 9
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, kast aluminium, middernacht, Sportbandje, 41mm, connectiviteit GPS , geen Apple Care+</b>	 <br />
          Waarde in Euro: € 498,75
        </p>

        <p className="content__text borderline">3x Pakket 10
        </p>
        <p className="content__text borderin">
          <b>Air Pods Pro (2e generatie)</b>	 <br />
          Waarde in Euro: € 299,00 
        </p>
        <p className="content__text borderin">
          <b>Home Pod mini	</b>	 <br />
          Waarde in Euro: € 109,00
        </p>
      </>
    )

  }
  render() {
    return (
      <div className="main__content">
        <p className="text__heading">VOORWAARDEN VOOR DEELNAME</p>
        <div className="content">
          <p className="content__text">
            Algemene prijs en organisator: Baker & Baker Germany GmbH is de
            enige sponsor van de prijzen in de zin van artikel 657 van het
            Duitse Burgerlijk Wetboek ("organisator"). D&K Brand Activation
            (Drewes & Keretic GmbH) treedt op als dienstverlener in opdracht van
            de organisator, verantwoordelijk voor het algehele beheer van de
            promotie en het leveren en verdelen van de prijzen. Consultix GmbH
            is een bureau dat de gegevens van deelnemers beheert en bewaart.
            Proximity (RAPP Worldwide, Inc.) en AUW (artundweise GmbH) zijn
            ingeschakeld als technische productiepartner en digitaal creatief en
            conceptbureau. Geen van deze derde partijen is als gevolg van de
            prijsvraag verplicht om hun eigen diensten aan te bieden. Winkeliers
            en socialemediaplatforms zijn geen organisator van de wedstrijd en
            treden ook niet op namens de organisator.
          </p>
          <p className="content__text">
            Geschiktheid: Iedereen ouder dan 18 jaar en permanent woonachtig in
            Duitsland, Oostenrijk, Zwitserland, het Verenigd Koninkrijk,
            Frankrijk, België, Nederland of Luxemburg kan deelnemen. Een
            vereiste om deel te nemen is dat er een correcte inschrijving heeft
            plaatsgevonden. Hiertoe moet het online formulier op de website{" "}
            <a href="www.donut-muffin.oreo.eu" target="_blank" rel="noreferrer">
              www.donut-muffin.oreo.eu
            </a>{" "}
            worden ingevuld en ingediend door de deelnemer en moeten deze
            deelnamevoorwaarden worden geaccepteerd door op het daarvoor
            bestemde selectievakje te klikken. Er is geen verplichting om een
            aankoop te doen om deel te nemen. Voor deelnemers die in België
            wonen, moet vanwege wettelijke vereisten een vraag correct worden
            beantwoord voordat ze in aanmerking komen voor deelname.
          </p>
          <p className="content__text">
            Uitgesloten van deelname zijn medewerkers en familieleden van
            medewerkers van de organisator en medewerkers en familieleden van
            medewerkers van D&K Brand Activation, Consultix GmbH, Proximity en
            AUW. Deelnames die worden uitgevoerd door dienstverleners in
            opdracht, zoals wedstrijddiensten voor hun klanten/leden, zijn
            uitdrukkelijk uitgesloten. Meerdere deelnames zijn niet toegestaan.
            De organisator behoudt zich het recht voor om deelnemers van de
            wedstrijd uit te sluiten die deze deelnamevoorwaarden schenden,
            onjuiste informatie verstrekken of op onrechtmatige wijze proberen
            het verloop van de wedstrijd te beïnvloeden. In dit geval hebben de
            uitgesloten deelnemers geen recht op uitbetaling van de prijs of
            enige andere vergoeding.
          </p>
          <p className="content__text">
            Annulering van de wedstrijd: De organisator behoudt zich het recht
            voor om de wedstrijd om gegronde redenen voortijdig te beëindigen
            indien om technische redenen (bijvoorbeeld manipulatie van de op de
            website gebaseerde wedstrijdpagina door derden of infectie van de
            website met virussen), om juridische redenen, of om andere redenen
            buiten de wil van de organisator, kan een goede uitvoering van de
            wedstrijd niet langer worden gegarandeerd. De organisator kan
            hiervoor niet aansprakelijk worden gesteld, behoudens opzet of grove
            schuld van de organisator. Bij annulering hebben de deelnemers geen
            recht op enige prijs of enige andere vergoeding.
          </p>
          <p className="content__text">
            Periode / deadline: Deelname is alleen mogelijk in de periode van 1
            september 00:00 uur 2023 tot 23:59 uur. op 31 maart 2024.
          </p>
          <p className="content__text">
            Prijzen: Er worden in totaal 18 winnaars geselecteerd, met prijzen
            variërend in waarde van ongeveer: £ 300 / € 400 / CHF 350, tot £
            4.200 / € 4.800 / CHF 5000. De winnaars krijgen automatisch een
            prijs toegewezen. De totale waarde van alle prijzen zal niet hoger
            zijn dan £ 29.000 / € 35.000 / CHF 31.200. Zie onderstaande
            prijzenlijst. Alle artikelen onder voorbehoud van beschikbaarheid.
            Indien artikelen op dat moment niet leverbaar zijn, worden deze
            vervangen door soortgelijke producten van minimaal gelijke waarde.
          </p>
          <p className="content__text">
            Prijsuitreiking: Prijzen worden binnen 4 weken na de trekking van de
            prijzen via een geschikte bezorgmethode verzonden.
          </p>
          <p className="content__text">
            Bepaling van de winnaar: De winnaars worden willekeurig bepaald uit
            alle geldige en correcte inzendingen op 1 april 2024. Er is geen
            gegarandeerde winnaar in elk land waar de wedstrijd wordt gehouden.
          </p>
          <p className="content__text">
            Prijskennisgeving, bevestigingsperiode, vervaldatum van de prijs: De
            winnaars worden door D&K Brand Activation op de hoogte gebracht op
            het e-mailadres dat ze op het registratieformulier hebben ingevuld
            en worden vervolgens gevraagd om een postadres op te geven voor het
            verzenden van de prijs. Een aanspraak op een prijs kan in principe
            niet worden overgedragen aan een andere persoon. Indien een winnaar
            de gevraagde gegevens niet binnen een termijn van 14 dagen na
            verzending van de prijsaankondiging verstrekt, of indien een
            prijsuitreiking niet mogelijk is vanwege onjuiste of onvolledige
            gegevens, wordt een andere winnaar geselecteerd en kan aanspraak
            worden gemaakt op de prijs van de oorspronkelijk bepaalde winnaar
            vervalt in dit opzicht. D&K Brand Activation zal de prijzen naar de
            respectievelijke winnaars sturen.
          </p>
          <p className="content__text">
            Aansprakelijkheid: De aansprakelijkheid van de organisator en de
            door hem ingeschakelde personen is uitgesloten. De uitsluiting van
            aansprakelijkheid geldt niet voor opzet of grove nalatigheid en
            overlijden of persoonlijk letsel. Voor gewone nalatigheid is de
            reikwijdte van de aansprakelijkheid beperkt tot de belangrijkste
            contractuele verplichtingen – namelijk de juiste bepaling van de
            prijswinnaars en de levering van de prijzen. De organisator is niet
            aansprakelijk jegens een winnaar in het geval dat een prijzenpakket
            niet (geheel of gedeeltelijk) wordt afgeleverd of beschadigd raakt
            tijdens het transport.
          </p>
          <p className="content__text">
            Diversen: Uitsluitend het recht van de Bondsrepubliek Duitsland is
            van toepassing. Deze bepaling doet geen afbreuk aan het recht van
            artikel 6, lid 2, van de Rome I-verordening, volgens hetwelk,
            ondanks de rechtskeuze, die dwingende bepalingen van toepassing zijn
            die van toepassing zouden zijn geweest zonder de uitdrukkelijke
            rechtskeuze. Mocht een van deze bepalingen ongeldig zijn of worden,
            dan blijft de geldigheid van de overige deelnamevoorwaarden
            onaangetast.
          </p>
          <p className="content__text">
            Gegevensbescherming: Mondelēz International (als eigenaar van het
            merk Oreo) is de gegevensbeheerder voor persoonlijke gegevens die
            door deelnemers worden verstrekt en Consultix GmbH zal persoonlijke
            gegevens verwerken voor de doeleinden van de wedstrijd.
          </p>
          <p className="content__text">
            De verplichte informatie over gegevensverwerking vereist door de
            Algemene Verordening Gegevensbescherming van de EU ("AVG") wordt
            hier samengevat (de privacyverklaring voor Mondelēz International is
            toegankelijk via:<br></br>
            <a
              href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=nl-NL&siteId=JoxksPYAnsA%2f0ghAJe2FI77HpKQFrykx "
              target="_blank"
              rel="noreferrer"
            >
              https://eu.mondelezinternational.com/privacy-notice?sc_lang=nl-NL&siteId=JoxksPYAnsA%2f0ghAJe2FI77HpKQFrykx{" "}
            </a>
          </p>
          <p className="content__text">
            Verantwoordelijk: Mondelēz International, Lindbergh-Allee 1, 8152
            Glattpark, Zwitserland, is als verwerkingsverantwoordelijke
            verantwoordelijk voor de gegevensbescherming
          </p>
          <p className="content__text">
            Functionaris voor gegevensbescherming: U kunt de functionaris voor
            gegevensbescherming van Mondelēz International bereiken op:{" "}
            <a href="mailto:MDLZDataProtectionOfficeMEU@mdlz.com">
              MDLZDataProtectionOfficeMEU@mdlz.com
            </a>{" "}
          </p>
          <p className="content__text">
            Gegevensverwerking: Om deel te nemen aan de prijsvraag via de
            website www.donut-muffin.oreo.eu moeten bij de registratie de
            volgende gegevens worden verstrekt: Titel, voornaam, achternaam,
            e-mailadres, adres en geboortedatum. Deze persoonsgegevens worden
            verwerkt om de wedstrijd uit te voeren, d.w.z. om de winnaars te
            bepalen, hen op de hoogte te stellen en hen de prijs te kunnen
            toesturen. De winnaars moeten ook de volgende informatie verstrekken
            voor de uitreiking van de prijzen: postadres. Als je wint, wordt je
            gevraagd dit apart per e-mail te doen. Als onderdeel van het
            e-mailverkeer worden logbestanden opgeslagen over de eigenschappen
            van de e-mail en het tijdstip van aankomst, alsmede alle door de
            afzender in de e-mail opgegeven gegevens.Legal basis: The processing
            of personal data in the context of registration and prize
            distribution takes place lawfully on the basis of the GDPR.
            Consultix GmbH processes the data to fulfill the contract in the
            form of carrying out the prize draw and the associated legal
            obligation, Article 6 Paragraph 1 lit. b) GDPR.
          </p>
          <p className="content__text">
            Doorgeven aan derden: Mondelēz International geeft de gegevens van
            de deelnemers niet door aan derden in de zin van de AVG.
          </p>
          <p className="content__text">
            Verwerkers: Mondelēz International maakt alleen gebruik van
            betrouwbare dienstverleners die uw persoonsgegevens verwerken. Zij
            krijgen een schriftelijke opdracht conform de strenge eisen van de
            AVG en beschikken bijvoorbeeld over technische en organisatorische
            maatregelen waarmee de dienstverleners de aan hen toevertrouwde
            gegevens beschermen tegen misbruik. Concreet heeft D&K Brand
            Activation de opdracht gekregen om de wedstrijd te managen en heeft
            Proximity de opdracht gekregen om de website voor de wedstrijd te
            verzorgen. Orderverwerking omvat alleen die diensten die
            rechtstreeks verband houden met de levering van de hoofddienst. Dit
            geldt niet voor ondersteunende diensten zoals
            Telecommunicatiediensten.
          </p>
          <p className="content__text">
            Doorgifte naar derde landen: Alle persoonsgegevens worden opgeslagen
            binnen de EU/EER, ook door eventuele verwerkers.
          </p>
          <p className="content__text">
            Duur van opslag: Mondelēz International en Consultix GmbH zullen
            verwijderen:
            <br />
          </p>
          <p className="content__text">
            <ul>
              <li>
                • de persoonsgegevens van de winnaars zodra er geen commerciële
                of fiscale bewaarplicht meer bestaat (deze laatste kan oplopen
                tot 10 jaar)
              </li>
              <li>
                • de persoonsgegevens van de andere deelnemers zodra de winnaars
                van de wedstrijd definitief zijn bepaald, d.w.z. er kunnen extra
                trekkingsprocedures hebben plaatsgevonden als gevolg van
                onvolledige informatie of het uitblijven van een reactie van een
                eerder geïdentificeerde winnaar
              </li>
              <li>
                • ontvangen e-mails met betrekking tot de wedstrijd: zes (6)
                maanden nadat de winnaars definitief zijn bepaald
              </li>
            </ul>
          </p>
          <p className="content__text">
            Cookies: zie{" "}
            <a
              href="https://nl.oreo.eu/cookiebeleid"
              target="_blank"
              rel="noreferrer"
            >
              https://nl.oreo.eu/cookiebeleid{" "}
            </a>
          </p>
          <p className="content__text">
            Uw rechten: Als aan de wettelijke vereisten is voldaan, hebt u de
            volgende rechten, die u tegenover Mondelēz International kunt doen
            gelden:
          </p>
          <p className="content__text">
            a. Recht op informatieverstrekking: U hebt te allen tijde het recht
            in het kader van art. 15 AVG om bevestiging van Mondelēz
            International te vragen of zij persoonsgegevens met betrekking tot u
            verwerken; als dit het geval is, hebt u ook recht op grond van art.
            15 AVG om informatie te verkrijgen over deze persoonsgegevens en
            bepaalde andere informatie (bijv. verwerkingsdoeleinden, categorieën
            van persoonsgegevens, categorieën van ontvangers, geplande
            opslagperiode, de herkomst van de gegevens, het gebruik van een
            geautomatiseerde besluitvorming en, in het geval van doorgiften naar
            derde landen, de passende waarborgen) en om een kopie van uw
            gegevens te ontvangen.
          </p>
          <p className="content__text">
            b. Recht op rectificatie: volgens art. 16 AVG hebt u het recht
            Mondelēz International te verzoeken de over u opgeslagen
            persoonsgegevens te corrigeren als deze onnauwkeurig of incorrect
            zijn.
          </p>
          <p className="content__text">
            c. Recht op gegevenswissing: U hebt het recht, onder de voorwaarden
            van art. 17 AVG, om te eisen dat Mondelēz International uw
            persoonlijke gegevens onmiddellijk verwijdert. Het recht op
            verwijdering bestaat onder andere niet als de verwerking van
            persoonsgegevens vereist is in overeenstemming met art. 17 lid 3
            AVG.
          </p>
          <p className="content__text">
            d. Recht op beperking van de verwerking: U hebt het recht, onder de
            voorwaarden van art. 18 AVG, om Mondelēz International te verzoeken
            de verwerking van uw persoonsgegevens te beperken.
          </p>
          <p className="content__text">
            e. Recht op gegevensoverdraagbaarheid: U hebt het recht, onder
            voorbehoud van de vereisten van art. 20 AVG, om te verzoeken dat
            Mondelēz International de relevante persoonlijke gegevens die u hebt
            verstrekt, overhandigt in een gestructureerd, gangbaar en
            machineleesbaar formaat.
          </p>
          <p className="content__text">
            f. Herroepingsrecht: U heeft het recht om uw toestemming voor de
            verwerking van persoonsgegevens te allen tijde met werking voor de
            toekomst in te trekken.
          </p>
          <p className="content__text">
            g. Recht van bezwaar: U heeft het recht, onder de voorwaarden van
            art. 21 AVG, om bezwaar te maken tegen de verwerking van uw
            persoonsgegevens, in het bijzonder op basis van een belangenafweging
            (Art. 6 Para. 1 lit. f) AVG).
          </p>
          <p className="content__text">
            Informatie over uw recht van bezwaar volgens art. 21 AVG U heeft te
            allen tijde het recht om bezwaar te maken tegen de verwerking van uw
            gegevens, die is gebaseerd op art. 6 lid. 1 f) AVG
            (gegevensverwerking op basis van belangenafweging), als daar redenen
            voor zijn die voortkomen uit uw specifieke situatie. Als u bezwaar
            maakt, zal Mondelēz International uw persoonsgegevens niet langer
            verwerken, tenzij zij dwingende legitieme gronden voor verwerking
            kan aantonen die zwaarder wegen dan uw belangen, rechten en
            vrijheden, of de verwerking dient om rechtsvorderingen in te
            stellen, uit te oefenen of te verdedigen. Het bezwaar kan in elke
            vorm worden ingediend en moet indien mogelijk worden gericht aan{" "}
            <a href="mailto:MDLZDataProtectionOfficeMEU@mdlz.com.">
              MDLZDataProtectionOfficeMEU@mdlz.com.</a>
          </p>
          <p className="content__text">Recht van beroep: U hebt ook het recht om contact op te nemen met een bevoegde toezichthoudende autoriteit in geval van klachten over gegevensbeschermingskwesties. De verantwoordelijke toezichthoudende autoriteit is dus</p>
          <p className="content__text">
            VERBRAUCHERSERVICE
            <br />
            MONDELEZ DEUTSCHLAND SERVICES GMBH & CO. KG VERBRAUCHERSERVICE
            <br />
            POSTFACH 10 78 40, 28078 BREMEN
            <br />
            HOTLINE DE/AT/CH: 00800 83 00 00 36 KOSTENFREI <br />
            Phone: HOTLINE DE/AT/CH: 00800 83 00 00 36 FREE OF CHARGE <br />
            Email:{" "}
            <a href="mailto:verbraucherservice@mdlz.com">
              VERBRAUCHERSERVICE@MDLZ.COM
            </a>
          </p>
          <p className="content__text">Annexe: Details van te winnen prijzen</p>
          <div className="prize">
            <p id="prize" className="content__text">Promotie start op 01.09.2023. Alle items zijn afhankelijk van beschikbaarheid op het moment van verzending naar de winnaars. Items die op dit moment niet beschikbaar zijn, worden vervangen door vergelijkbare items van minimaal gelijke waarde.</p>
          </div>
          {/* <p className="content__text">1x Pakket 1	<br />Waarde in Euro<br />
Ultieme meeslepende set met Arc, Sub, 2 Era 300	 <br />€ 2.896,00 <br />
Samsung TV 50" Neo QLED 4K QN90C	 <br />€ 1.999,00 
</p>
          <p className="content__text">1x Pakket 2	<br />Waarde in Euro<br />
Samsung TV 75" Neo QLED 8K QN800B	 <br />€ 3.089,00 <br />
Microsoft, XBox Series X, geen game	 <br />€ 499,90<br /> 
Xbox Elite draadloze controller Series 2, 2 stukken	 <br />€ 290,00 <br />
Sonos ARC Soundbar, zwart	 <br />€ 999,00 
</p>
          <p className="content__text">1x Pakket 3	<br />Waarde in Euro<br />
iPad Pro 12,9'', Spacegrijs, 512 GB, Wi-Fi,
geen inscriptie, geen pencil, geen toetsenbord, geen Apple Care+	 <br />€ 1.849,45<br /> 
Apple Watch Ultra, geen Apple Care+	 <br />€ 998,75 <br />
Canon EOS R7-systeemcamera en RF-S 18-150mm F3.5-6.3 IS STM-lens	 <br />€ 1.999,00 
</p>
          <p className="content__text">2x Pakket 4	<br />Waarde in Euro<br />
Canon EOS R7-systeemcamera en RF-S 18-150mm F3.5-6.3 IS STM-lens	 <br />€ 1.999,00 <br />
Apple Watch Ultra, geen Apple Care+	 <br />€ 998,75 
</p>
          <p className="content__text">2x Pakket 5	<br />Waarde in Euro<br />
iPad Pro 12,9'', Spacegrijs, 512 GB, Wi-Fi,
geen inscriptie, geen pencil, geen toetsenbord, geen Apple Care+	 <br />€ 1.849,45 <br />
Apple Watch Ultra, geen Apple Care+	 <br />€ 998,75 
</p>
          <p className="content__text">
2x Pakket 6	<br />Waarde in Euro<br />
Sonos ARC Soundbar, zwart	<br />€ 999,00
</p>
          <p className="content__text">1x Pakket 7	<br />Waarde in Euro<br />
Apple Watch Ultra, geen Apple Care+	 <br />€ 998,75
</p>
          <p className="content__text">2x Pakket 8	<br />Waarde in Euro<br />
Apple Watch Series 8, kast aluminium, middernacht, Sportbandje, 41mm, connectiviteit GPS , geen Apple Care+	 <br />€ 498,75 <br />
Air Pods Pro (2e generatie)	 <br />€ 299,00 
</p>
          <p className="content__text">3x Pakket 9	<br />Waarde in Euro<br />
Apple Watch Series 8, kast aluminium, middernacht, Sportbandje, 41mm, connectiviteit GPS , geen Apple Care+	<br />€ 498,75
</p>
          <p className="content__text">3x Pakket 10	<br />Waarde in Euro<br />
Home Pod mini	 <br />€ 109,00 
</p> */}
          {this.Table()}
        </div>
      </div>
    );
  }
}

export default Nl;
