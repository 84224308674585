import axios from "axios";

const campaign = () => {
    const _URL = "/api/campaigngate/status/fr-fr";
    let _SERVICE_URL = "";
    const isProd = "donut-muffin.oreo.eu", hiddenProd = "proximitybbdo-donut-muffin-oreo-eu";
    
    if (window.location.hostname.toLowerCase().indexOf(isProd) > -1){
        _SERVICE_URL = "https://api.donut-muffin.oreo.eu";
    } else if (window.location.hostname.toLowerCase().indexOf(hiddenProd) > -1){
        _SERVICE_URL = "https://proximitybbdo-donut-muffin-oreo-eu-api-v25ef3pzhq-ey.a.run.app"
    } else {
        _SERVICE_URL = "https://api-donut-muffin-oreo-eu.mdlzapps.cloud"
    }

    var LoadCampaign = () => {
        var campaignURL = _URL,
            locale = window.location.pathname.split('/')[1].length > 0 && window.location.pathname.split('/')[1].length < "en-us".length + 1 ? window.location.pathname.split('/')[1] : "en-us";

        campaignURL += `?locale=${locale}`
        return axios({
            method: 'get',
            url: campaignURL
        })
        /**
         * {
            * "success":true,
            * "message":null,
            * "data":{
                * "statusMessage":"COUNTRY_NOT_PARTICIPATING",
                * "countryCode":"MU",
                * "locale":"en-gb"
            * }
         * }
         */
    }

    const CampaignOreo = () => {
        const local = window.location.pathname.split('/')[1].length > 0 && window.location.pathname.split('/')[1].length < "de-de".length + 1 ? window.location.pathname.split('/')[1] : "";
        /**
            {
                "success": true,
                "message": null,
                "data": {
                    "status": "main", // REDIRECT, COUNTRY_NOT_PARTICIPATING, holding, main, end
                    "countryCode": "FR", // DE, AT, CH, GB, FR, BE, NL, LU, MU ... 
                    "locale": "fr-fr" // de-de, de-at, be-fr ...
                }
            }
        **/
        return axios({
            method: 'get',
            url: `${_SERVICE_URL}/CampaignStatusOreo/${local}`,
        })
    };

    return {
        LoadCampaign,
        CampaignOreo
    }
}

var CampaignServices = campaign();
export default CampaignServices;