import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import appGoogleTracking from "../../modules/GoogleTracking";

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

class footerFcMilka extends Component {
  render() {
    const { t } = this.props;
    return (
      <div
        className="grid-2-m grid-m-12 grid-s-2-m footer__category js-accordion"
        data-target="footer-card-5"
      >
        <h4 className="js-footer-accordion" data-target="footer-card-5">
          <a
            className="footer__title js-event-cta footer-desktop"
            data-target="footer-card-5"
            target="_blank"
            rel="noopener noreferrer"
            data-event="info-click"
            data-category="Informational Action"
            data-action="Footer Link"
            data-label="FC Milka"
            href={t("footer.fcMilkaSection.fcMilkaUrl")}
            title="FC Milka"
            // onClick={appGoogleTracking.processEventCTA}
          >
            {t("footer.fcMilkaSection.fcMilka")}
          </a>
          <a
            className="footer__title js-event-cta footer-mobile"
            data-target="footer-card-5"
          >
            {t("footer.fcMilkaSection.fcMilka")}
          </a>
          <span
            className="footer__control js-footer-accordion-icon"
            data-target="footer-card-5"
          ></span>
        </h4>
        <hr />
        <div
          className="grid-column footer__links js-footer-accordion-content"
          data-target-child="footer-card-5"
        >
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.fcMilkaSection.bundesUrl")}
              target="_blank"
              rel="noopener noreferrer"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Milka Bundesliga"
              title="Milka und die Bundesliga"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.fcMilkaSection.bundes")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.fcMilkaSection.fcAccountUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="FC Milka account"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="FC Milka account"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.fcMilkaSection.fcMilka")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.fcMilkaSection.fanCenterUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Fan-Center"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Fan-Center"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.fcMilkaSection.fanCenter")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.fcMilkaSection.buzzerUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Bundesliga Buzzer"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Bundesliga Buzzer"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.fcMilkaSection.buzzer")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.fcMilkaSection.meterUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="11-Meter-Schießen"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="11-Meter-Schießen"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.fcMilkaSection.meter")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.fcMilkaSection.fairplayUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Fairplay"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Fairplay"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.fcMilkaSection.fairplay")}
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(footerFcMilka);
