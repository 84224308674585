import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import appGoogleTracking from '../modules/GoogleTracking'
import StickyHeader from '../modules/StickyHeader'
import HamburgerMenu from '../modules/HamburgerMenu'

class Header extends Component {
  componentDidMount() {
    if (
      (this.props.urlPrefix === 'de-de') |
      (this.props.urlPrefix === 'de-at') |
      (this.props.urlPrefix === 'fr-fr')
    ) {
      new StickyHeader({
        headerSelector: '.js-header.header-md__container',
        contentSelector: '.js-header-md-content',
        iconSelector: '.js-header-md-hamburger',
        linkSelector: '.header-md__navigation',
      })

      new StickyHeader({
        headerSelector: '.js-header.header__container .header__head',
        linkSelector: '.header__navigation',
      })

      new HamburgerMenu({
        menuSelector: '.js-hamburger',
        contentSelector: '.js-ham-content',
        modifier: 'header__menu--open',
        close: '.js-ham-close',
      })
    }
  }

  render() {
    const { t } = this.props
    let navDesktop = null,
      navMobile = null,
      shopMob = null,
      shopDesk = null

    if (window.URL_PREFIX === 'de-de' || window.URL_PREFIX === 'de-at') {
      shopMob = (
        <li>
          <a
            className="header__navigation js-event-cta"
            data-event="Header Tab"
            data-category="Informational Action"
            data-action="Header Tab"
            data-label="SHOP"
            target="_blank"
            rel="noopener noreferrer"
            href={t('header.shopUrl')}
            // onClick={appGoogleTracking.processEventCTA}
          >
            {t('header.shop')}
          </a>
        </li>
      )
      shopDesk = (
        <li>
          <a
            className="header-md__navigation js-event-cta"
            href={t('header.shopUrl')}
            data-event="info-click"
            data-category="Informational Action"
            data-action="Header Tab"
            data-label="SHOP"
            target="_blank"
            rel="noopener noreferrer"
            // onClick={appGoogleTracking.processEventCTA}
          >
            {t('header.shop')}
          </a>
        </li>
      )
    }

    //nav by country
    if (window.URL_PREFIX === 'de-de' || window.URL_PREFIX === 'de-at') {
      navMobile = (
        <div className="header__navigations">
          <ul>
            <li>
              <a
                className="header__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="PRODUKTE"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.productUrl')}
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.product')}
              </a>
            </li>
            <li>
              <a
                className="header__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="FC MILKA"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.fcmilka.de/"
              >
                FC MILKA
              </a>
            </li>
            <li>
              <a
                className="header__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="NEUES"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.newsUrl')}
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.news')}
              </a>
            </li>
            {shopMob}
            <li>
              <a
                className="header__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="UBER MILKA"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.aboutUrl')}
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.about')}
              </a>
            </li>
          </ul>
          <div className="header__end"></div>
        </div>
      )
      navDesktop = (
        <div className="js-header-md-content header-md__content">
          <ul className="header-md__navigations">
            <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="PRODUKTE"
                href={t('header.productUrl')}
                target="_blank"
                rel="noopener noreferrer"
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.product')}
              </a>
            </li>
            <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="NEUES"
                href={t('header.newsUrl')}
                target="_blank"
                rel="noopener noreferrer"
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.news')}
              </a>
            </li>
           <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="FC MILKA"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.fcmilka.de/"
              >
                FC MILKA
              </a>
            </li>
            {shopDesk}
            <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="UBER MILKA"
                href={t('header.aboutUrl')}
                target="_blank"
                rel="noopener noreferrer"
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.about')}
              </a>
            </li>
          </ul>
          <ul className="header-md__icons">
            <li className="header-md__icon header-md__country">
              <a
                href={t('header.profilUrl')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={t('header.profil')}></span>
              </a>
            </li>
            <li className="header-md__icon header-md__country">
              <a
                href={t('header.shoppingUrl')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={t('header.shopping')}></span>
              </a>
            </li>
            <li className="header-md__icon header-md__country">
              <a
                href={t('header.searchUrl')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={t('header.search')}></span>
              </a>
            </li>
            <li className="header-md__icon header-md__contact">
              <a
                className="js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="ContactFormUrl"
                href={t('header.contactUrl')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={t('header.contact')}></span>
              </a>
            </li>
            <li className="header-md__icon header-md__country">
              <a
                href={t('header.countryUrl')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={t('header.country')}></span>
              </a>
            </li>
          </ul>
        </div>
      )
    } else if (window.URL_PREFIX === 'de-at') {
      navMobile = (
        <div className="header__navigations">
          <ul>
            <li>
              <a
                className="header__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="PRODUKTE"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.productUrl')}
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.product')}
              </a>
            </li>
            <li>
              <a
                className="header__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="NEUES"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.newsUrl')}
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.news')}
              </a>
            </li>
            {shopMob}
            <li>
              <a
                className="header__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="UBER MILKA"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.aboutUrl')}
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.about')}
              </a>
            </li>
          </ul>
          <div className="header__end"></div>
        </div>
      )
      navDesktop = (
        <div className="js-header-md-content header-md__content">
          <ul className="header-md__navigations">
            <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="PRODUKTE"
                href={t('header.productUrl')}
                target="_blank"
                rel="noopener noreferrer"
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.product')}
              </a>
            </li>
            <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="NEUES"
                href={t('header.newsUrl')}
                target="_blank"
                rel="noopener noreferrer"
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.news')}
              </a>
            </li>
            {shopDesk}
            <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="UBER MILKA"
                href={t('header.aboutUrl')}
                target="_blank"
                rel="noopener noreferrer"
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.about')}
              </a>
            </li>
          </ul>
          <ul className="header-md__icons">
            <li className="header-md__icon header-md__contact">
              <a
                className="js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="ContactFormUrl"
                href={t('header.contactUrl')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={t('header.contact')}></span>
              </a>
            </li>
            <li className="header-md__icon header-md__country">
              <a
                href={t('header.countryUrl')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={t('header.country')}></span>
              </a>
            </li>
          </ul>
        </div>
      )
    } else if (window.URL_PREFIX === 'fr-fr') {
      navMobile = (
        <div className="header__navigations">
          <ul>
            <li>
              <a
                className="header__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="PRODUKTE"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.productUrl')}
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.product')}
              </a>
            </li>
            <li>
              <a
                className="header__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="UBER MILKA"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.aboutUrl')}
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.about')}
              </a>
            </li>
          </ul>
          <div className="header__end"></div>
        </div>
      )
      navDesktop = (
        <div className="js-header-md-content header-md__content">
          <ul className="header-md__navigations">
            <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="PRODUKTE"
                href={t('header.productUrl')}
                target="_blank"
                rel="noopener noreferrer"
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.product')}
              </a>
            </li>
            <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="UBER MILKA"
                href={t('header.aboutUrl')}
                target="_blank"
                rel="noopener noreferrer"
                // onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.about')}
              </a>
            </li>
          </ul>
          <ul className="header-md__icons">
            <li className="header-md__icon header-md__contact">
              <a
                className="js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="ContactFormUrl"
                href="https://contactus.mdlzapps.com/form?siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon-contact"></span>
              </a>
            </li>
            <li className="header-md__icon header-md__country">
              <a
                href={t('header.countryUrl')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={t('header.country')}></span>
              </a>
            </li>
          </ul>
        </div>
      )
    }
    return (
      <>
        <header>
          <nav className="js-header header__container">
            <div className="header__head">
              <img
                className="header__pull js-pull-animate"
                src="resources/images/icons/Pull_Out_Shape.svg"
                alt="print"
              />
              <div className="js-hamburger header__menu" id="hamburger-m">
                <span className="menu-line"></span>
                <span className="menu-line"></span>{' '}
                <span className="menu-line"></span>
                <span className="menu-line"></span>
              </div>
              <div className="header__logo">
                <a href={`/${window.URL_PREFIX}/`} rel="noopener noreferrer">
                  <span className="icon-milka-logo"></span>
                  {/* <img
                    src="/resources/images/logo.png"
                    width="200"
                    height="134"
                    alt="Milka logo"
                  /> */}
                </a>
              </div>
              <div className="header__icons">
                <a
                  href={t('header.countryUrl')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={t('header.country')}></span>
                </a>
                <a
                  href={t('header.shoppingUrl')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={t('header.shopping')}></span>
                </a>
              </div>
            </div>
            <div className="header__content js-ham-content">
              <div className="header__head">
                <div className="js-ham-close">
                  <span className="icon-home"></span>
                </div>
              </div>
              {navMobile}
            </div>
          </nav>
          <nav className="js-header header-md__container">
            <div className="header-md__logo">
              <a
                className="nav__item js-event-cta"
                data-event="info-click"
                data-category="Informational Action"
                data-action="Header Tab"
                data-label="Milka"
                href={`/${window.URL_PREFIX}/`}
                rel="noopener noreferrer"
              >
                <span className="icon-milka-logo"></span>
                {/* <img
                  src="/resources/images/logo.png"
                  width="200"
                  height="134"
                  alt="Milka logo"
                /> */}
              </a>
            </div>
            <div className="js-header-md-hamburger header-md__menu">
              <div className="header__btn" id="hamburger">
                <span className="menu-line"></span>
                <span className="menu-line"></span>
                <span className="menu-line"></span>
                <span className="menu-line"></span>
              </div>
            </div>
            {navDesktop}
          </nav>
        </header>
      </>
    )
  }
}

export default withTranslation()(Header)
