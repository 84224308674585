export function countryLanguage(locale){
  let language = 'en';

  let array_local = [
    { country: 'de-de', language: 'de' },  //Germany
    { country: 'de-at', language: 'de' },  //Austria
    { country: 'nl-nl', language: 'nl' },  //Netherlands
    { country: 'nl-be', language: 'be' },  //Belgium Dutch
    { country: 'fr-be', language: 'be' },  //Belgium french
    { country: 'et-et', language: 'ee' },  //Estonia
    { country: 'lv-lv', language: 'lv' },  //Latvia
    { country: 'lt-lt', language: 'lt' },  //Lithuania
    { country: 'pl-pl', language: 'pl' },  //Poland
    { country: 'cs-cz', language: 'cs' },  //Czech Republic
    { country: 'sk-sk', language: 'sk' },  //Slovakia
    { country: 'fr-fr', language: 'fr' },  //France
    { country: 'es-es', language: 'es' },  //Spain
    { country: 'pt-pt', language: 'pt' },  //Portugal
    { country: 'ru-ru', language: 'ru' },  //Russia
    { country: 'ro-ro', language: 'ro' },  //Romania
    { country: 'bg-bg', language: 'bg' },  //Bulgaria
    { country: 'hu-hu', language: 'hu' },  //Hungaria
    { country: 'rs-rs', language: 'sr' },  //Serbia
    { country: 'me-me', language: 'me' },  //Montenegro
    { country: 'hr-hr', language: 'hr' },  //Croatia
    { country: 'sl-sl', language: 'sl' },  //Slovenia
    { country: 'uk-ua', language: 'uk' },  //Ukraine
  ]
}

export function ServicesUrl() {
  var hostName = window.location.hostname,
    isProd = hostName.toLowerCase().indexOf('donut-muffin.oreo.eu') > -1 ? true : false,
    isQA = hostName.toLowerCase().indexOf('donut-muffin-oreo-eu.mdlzapps.cloud') > -1 ? true : false,
    isLocal = hostName.toLowerCase().indexOf('localhost') > -1 ? true : false

  if (isProd) {
    return 'https://api.donut-muffin.oreo.eu';
  }
  else if(isQA){
    return 'https://api-donut-muffin-oreo-eu.mdlzapps.cloud';
  } else if(isLocal){
    return 'https://api-donut-muffin-oreo-eu.mdlzapps.cloud';
  }
  return 'https://api-donut-muffin-oreo-eu.mdlzapps.cloud';
}


export function mainLoaderToggle(status){
  let loaderSelector = document.querySelector('.js-main-loader');

  if(status === 'show'){
      loaderSelector.classList.add('active');
  } else {
      loaderSelector.classList.remove('active');
  }
}

export function servicePath(param){
  let returnPath = '';

  //Campaign promotion webservice path
  if(param.status === 'global'){
      returnPath = param.campaignURL;
  }
  //Participation webservice path
  else if(param.status === 'participation'){
      returnPath = param.participationURL;
  }

  return returnPath;
}

export function scrollToElement(){
  document.querySelector('.navigation-newsletter').scrollIntoView({ behavior: 'smooth', block: 'start'});
}

// get url and if they have param like #prize it automaticly point on this class
export function scrollToElementwithTimeout() {
  const url = window.location.href;
  const parts = url.split('#');
  const id = parts[1];
  setTimeout(() => {
    let element = document.querySelector('.' + `${id}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }
  }, 500);
}

//Timestamp for Tagging plan - can be customise for different format
//Return format : MM/DD/YYYY
export function timeStamp(){
  let date        = new Date(),
      day         = date.getDate(),
      month       = date.getMonth() + 1,
      dayformat   = (day < 10) ? '0'+day : day,
      monthformat = ( month < 10) ? '0'+month  : month,
      timeStamp   = monthformat+'/'+dayformat+'/'+date.getFullYear();

  return timeStamp;
}

export default {mainLoaderToggle, scrollToElement, timeStamp};