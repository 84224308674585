const isProd = window.location.hostname.includes("netstg") ? false : true;

const GLOBAL_CONFIG = {
  ServiceSettings: {
    campaignURL: '/api/campaigngate/status',
    participationURL: ''
  },

  Route: {
    home: '/',
    holding: "/",
    end: '/',
    main: "/",
    thankYou: '/thank-you',
    privacyNotice: '/privacy-notice',
    noparticipation: '/not-participating',
    cookiePolicy: '/cookies',
    termsAndConditions: '/terms-and-conditions'
  },

  Captcha: {
    siteKey: '6LdkRKYnAAAAAG4i06Fqa_N7VyF2aYoCSpaRPQU0', 
    dev: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', 
    prod: '6Lc9tu4nAAAAAFVFgFoVop36bTeePlgyLYTUOV7c'
  },

  GTM: {},

  Localisation: {
    path: "/resources/localisation/"
  },

  Cookie: {},

  CookieGTM: {}
}

export const GetRoute = (locale) => {
  switch (locale.toLowerCase()) {
    case "de-de":
    case "de-at":
    case "de-ch":
    case "lb-lu":
      return {
        home: '/',
        holding: '/',
        end: '/',
        main: '/',
        thankYou: '/danke',
        privacyNotice: '/datenschutzhinweise',
        noparticipation: '/not-participating',
        cookiePolicy: '/cookies',
        termsAndConditions: '/teilnahmebedingungen'
      };
      break;
    case "fr-fr":
    case "fr-ch":
    case "fr-be":
    case "fr-lu":
      return {
        home: '/',
        holding: '/',
        end: '/',
        main: '/',
        thankYou: '/merci',
        privacyNotice: '/avis-de-confidentialité',
        noparticipation: '/not-participating',
        cookiePolicy: '/cookies',
        termsAndConditions: '/termes-et-conditions'
      };
      break;
    case "en-gb":
      return {
        home: '/',
        holding: "/",
        end: '/',
        main: "/",
        thankYou: '/thank-you',
        privacyNotice: '/privacy-notice',
        noparticipation: '/not-participating',
        cookiePolicy: '/cookies',
        termsAndConditions: '/terms-and-conditions'
      };
      break;
    case "nl-nl": 
    case "nl-be":
      return {
        home: '/',
        holding: "/",
        end: '/',
        main: "/",
        thankYou: '/thank-you',
        privacyNotice: '/privacy-notice',
        noparticipation: '/not-participating',
        cookiePolicy: '/cookies',
        termsAndConditions: '/terms-and-conditions'
      };
      break;
  
    default:
      return {};
      break;
  }
}

export default GLOBAL_CONFIG;