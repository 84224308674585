import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class footercopyright extends Component {
  render() {
    const { t } = this.props
    let copyright = null

    if (window.URL_PREFIX === 'de-de') {
      copyright = (
        <h4>
          2022 MONDELEZ DEUTSCHLAND SERVICES <br></br>GMBH &amp; CO. KG - <br></br><br></br>ALL
          RIGHTS RESERVED
        </h4>
      )
    } else if (window.URL_PREFIX === 'de-at') {
      copyright = (
        <h4>
          2022 MONDELEZ ÖSTERREICH<br></br> GMBH &amp; co. kg - <br></br><br></br>all rights reserved
        </h4>
      )
    } else if (window.URL_PREFIX === 'fr-fr') {
      copyright = (
        <h4>2021 Mondelez services gmbh &amp; co. kg - all rights reserved</h4>
      )
    }
    return (
      <div className="grid-3-m grid-m-12 grid-s-2-m footer__copyright">
        <a
          href="#"
          title="MDLZ International"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          <span className="icon-mdlz"></span>
        </a>
        {copyright}
      </div>
    )
  }
}

export default footercopyright
