import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import appGoogleTracking from "../../modules/GoogleTracking";

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

class footerproduct extends Component {
  render() {
    const { t } = this.props;
    let footerProduct = null,
        brotauf = null;

        if (window.URL_PREFIX === "de-de") {
          brotauf = (
            <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.brotaufUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Schokoriegel"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Brotaufstrich"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.brotauf")}
            </a>
          </span>
          );
        }

    if ((window.URL_PREFIX === "de-de") | (window.URL_PREFIX === "de-at")) {
      footerProduct = (
        <div
          className="grid-column footer__links js-footer-accordion-content"
          data-target-child="footer-card-1"
        >
          {/* <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.weihnachtenUrl")}
              target="_blank"
              rel="noopener noreferrer"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="weihnachten"
              title="weihnachten"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.weihnachten")}
            </a>
          </span> */}
         <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.neuUrl")}
              target="_blank"
              rel="noopener noreferrer"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Neu"
              title="Neu"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.neu")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.tafelUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Tafel Schokolade"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Tafel Schokolade"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.tafel")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.kekseUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Kekse und Küchlein"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Kekse und Küchlein"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.kekse")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.pralinenUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Pralinen"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Pralinen"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.pralinen")}
            </a>
          </span>
          
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.schokoUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Schoko Snacks"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Schoko Snacks"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.schoko")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.schokoriegelUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Schokoriegel"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Schokoriegel"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.schokoriegel")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.brotaufUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Brotaufstrich"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Brotaufstrich"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.brotauf")}
            </a>
          </span>
          
         
        </div>
      );
    } else if (window.URL_PREFIX === "fr-fr") {
      footerProduct = (
        <div
          className="grid-column footer__links js-footer-accordion-content"
          data-target-child="footer-card-1"
        >
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.neuUrl")}
              target="_blank"
              rel="noopener noreferrer"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Neu"
              title="Neu"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.neu")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.tafelUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Tafel Schokolade"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Tafel Schokolade"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.tafel")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.kekseUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Kekse und Küchlein"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Kekse und Küchlein"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.kekse")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.pralinenUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Pralinen"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Pralinen"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.pralinen")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.productSection.schokoUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Schoko Snacks"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Schoko Snacks"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.productSection.schoko")}
            </a>
          </span>
        </div>
      );
    }
    return (
      <div
        className="grid-2-m grid-m-12 grid-s-2-m footer__category js-accordion"
        data-target="footer-card-1"
      >
        <h4 className="js-footer-accordion" data-target="footer-card-1">
          <a
            className="footer__title js-event-cta footer-desktop"
            data-target="footer-card-1"
            target="_blank"
            rel="noopener noreferrer"
            data-event="info-click"
            data-category="Informational Action"
            data-action="Footer Link"
            data-label="Produkte"
            href={t("footer.productSection.productUrl")}
            title="Produkte"
            // onClick={appGoogleTracking.processEventCTA}
          >
            {t("footer.productSection.product")}
          </a>
          <a
            className="footer__title js-event-cta footer-mobile"
            data-target="footer-card-1"
          >
            {t("footer.productSection.product")}
          </a>
          <span
            className="footer__control js-footer-accordion-icon"
            data-target="footer-card-1"
          ></span>
        </h4>
        <hr />
          {footerProduct}
      </div>
    );
  }
}

export default withTranslation()(footerproduct);
