import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import FooterLogo from './FooterContent/FooterLogo'
import FooterProduct from './FooterContent/FooterProduct'
import FooterShop from './FooterContent/FooterShop'
import FooterNews from './FooterContent/FooterNews'
import FooterAbout from './FooterContent/FooterAbout'
import FooterLinks from './FooterContent/FooterLinks'
import FooterSocial from './FooterContent/FooterSocial'
import FooterNewsletter from './FooterContent/FooterNewsletter'
import FooterCopyright from './FooterContent/FooterCopyright'
import FooterAccordion from '../modules/FooterAccordion'
import Scrolltop from '../modules/ScrollTop'
import FooterFcMilka from './FooterContent/FooterFcMilka'
import FooterNews2 from './FooterContent/FooterNews2'

class Footer extends Component {
  componentDidMount() {
    if (
      (this.props.urlPrefix === 'de-de') |
      (this.props.urlPrefix === 'de-at') |
      (this.props.urlPrefix === 'fr-fr')
    ) {
      new Scrolltop({
        selector: '.js-scroll-top',
        heightActive: 600,
      })

      new FooterAccordion()
    }
  }

  render() {
    let countryFooter = null

    //footer by country
    if (window.URL_PREFIX === 'de-de' || window.URL_PREFIX === 'de-at') {
      countryFooter = (
        <footer className="footer__container">
          <div className="grid-row footer__head">
            <div className="footer__top js-scroll-top">
              <img
                src="/resources/images/icons/Footer/Uplift_Button.svg"
                alt="To Top"
              />
            </div>
            <FooterLogo />
            <FooterProduct />
            <FooterNews />
            <FooterFcMilka />
            <FooterShop />
            <FooterAbout />
          </div>
          <div className="footer__external">
            <FooterLinks />
            <FooterSocial />
          </div>
          <div className="grid-row footer__foot">
            <picture>
              <source
                srcSet="/resources/images/Pages/lila-curve-footer-DESK.png"
                media="(min-width: 1024px)"
              />
              <source
                srcSet="/resources/images/Pages/lila-curve-footer-DESK.png"
                media="(min-width: 481px)"
              />
              <source
                srcSet="/resources/images/Pages/milka-relaunch-2020-footer-mobile.png"
                media="(min-width: 200px)"
              />
              <img
                src="/resources/images/Pages/lila-curve-footer-DESK.png"
                alt="Default ALT"
                height="100"
                width="100"
              />
            </picture>
            <div className="grid-row footer__foot-content">
              <FooterNewsletter />
              <FooterCopyright />
            </div>
          </div>
        </footer>
      )
    } else if (window.URL_PREFIX === 'de-at') {
      countryFooter = (
        <footer className="footer__container">
          <div className="grid-row footer__head">
            <div className="footer__top js-scroll-top">
              <img
                src="/resources/images/icons/Footer/Uplift_Button.svg"
                alt="To Top"
              />
            </div>
            <FooterLogo />
            <FooterProduct />
            <FooterNews />
            <FooterAbout />
          </div>
          <div className="footer__external">
            <FooterLinks />
            <FooterSocial />
          </div>
          <div className="grid-row footer__foot">
            <picture>
              <source
                srcSet="/resources/images/Pages/lila-curve-footer-DESK.png"
                media="(min-width: 1024px)"
              />
              <source
                srcSet="/resources/images/Pages/lila-curve-footer-DESK.png"
                media="(min-width: 481px)"
              />
              <source
                srcSet="/resources/images/Pages/milka-relaunch-2020-footer-mobile.png"
                media="(min-width: 200px)"
              />
              <img
                src="/resources/images/Pages/lila-curve-footer-DESK.png"
                alt="Default ALT"
                height="100"
                width="100"
              />
            </picture>
            <div className="grid-row footer__foot-content">
              <FooterNewsletter />
              <FooterCopyright />
            </div>
          </div>
        </footer>
      )
    } else if (window.URL_PREFIX === 'fr-fr') {
      countryFooter = (
        <footer className="footer__container">
          <div className="grid-row footer__head">
            <div className="footer__top js-scroll-top">
              <img
                src="/resources/images/icons/Footer/Uplift_Button.svg"
                alt="To Top"
              />
            </div>
            <FooterLogo />
            <FooterProduct />
            <FooterAbout />
          </div>
          <div className="footer__external">
            <FooterLinks />
            <FooterSocial />
          </div>
          <div className="grid-row footer__foot">
            <picture>
              <source
                srcSet="/resources/images/Pages/lila-curve-footer-DESK.png"
                media="(min-width: 1024px)"
              />
              <source
                srcSet="/resources/images/Pages/lila-curve-footer-DESK.png"
                media="(min-width: 481px)"
              />
              <source
                srcSet="/resources/images/Pages/milka-relaunch-2020-footer-mobile.png"
                media="(min-width: 200px)"
              />
              <img
                src="/resources/images/Pages/lila-curve-footer-DESK.png"
                alt="Default ALT"
                height="100"
                width="100"
              />
            </picture>
            <div className="grid-row footer__foot-content">
              <FooterNewsletter />
              <FooterCopyright />
            </div>
          </div>
        </footer>
      )
    }

    return <>{countryFooter}</>
  }
}
export default withTranslation()(Footer)
