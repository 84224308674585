import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import appGoogleTracking from '../../modules/GoogleTracking'
import FooterSocial from './FooterSocial'
import FooterLinks from './FooterLinks'

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

class footerabout extends Component {
  render() {
    const { t } = this.props
    let footerAbout = null

    if (window.URL_PREFIX === 'de-de' || window.URL_PREFIX === 'de-at') {
      footerAbout = (
        <div
          className="grid-column footer__links js-footer-accordion-content"
          data-target-child="footer-card-4"
        >
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.geschichteUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="History of Milka"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="History of Milka"
              //onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.geschichte')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.geschmackUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Geschmack"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Geschmack"
              //onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.geschmack')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.schokoUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Schokoladenherstellung"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Schokoladenherstellung"
              //onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.schoko')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.unserUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Unser Engagement"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Unser Engagement"
              //onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.unser')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.nationalUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Nationalpark Hohe Tauern"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Nationalpark Hohe Tauern"
              //onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.national')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.skiUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Skisponsoring"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Skisponsoring"
              //onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.ski')}
            </a>
          </span>
           <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.skiGeschUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Skisponsoring Geschichte"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Skisponsoring Geschichte"
              //onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.skiGesch')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.fakeUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Fake Gewinnspiele"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Fake Gewinnspiele"
              //onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.fake')}
            </a>
          </span>
        </div>
      )
    } else if (window.URL_PREFIX === 'de-at') {
      footerAbout = (
        <div
          className="grid-column footer__links js-footer-accordion-content"
          data-target-child="footer-card-4"
        >
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.geschichteUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Geschichte"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Geschichte"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.geschichte')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.geschmackUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Geschmack"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Geschmack"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.geschmack')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.unserUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Unser Engagement"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Unser Engagement"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.unser')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.national')}
              target="_blank"
              rel="noopener noreferrer"
              title="Nationalpark Hohe Tauern"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Nationalpark Hohe Tauern"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.national')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.lilaUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Nationalpark Hohe Tauern"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Nationalpark Hohe Tauern"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.lila')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.schokoUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Schokoladenherstellung"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Schokoladenherstellung"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.schoko')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.tierUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Nationalpark Hohe Tauern"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Nationalpark Hohe Tauern"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.tier')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.skiUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Skisponsoring"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Skisponsoring"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.ski')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.skiGeschUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Skisponsoring Geschichte"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Skisponsoring Geschichte"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.skiGesch')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.fakeUrl')}
              target="_blank"
              rel="noopener noreferrer"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Fake Gewinnspiele"
              title="Fake Gewinnspiele"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.fake')}
            </a>
          </span>
        </div>
      )
    } else if (window.URL_PREFIX === 'fr-fr') {
      footerAbout = (
        <div
          className="grid-column footer__links js-footer-accordion-content"
          data-target-child="footer-card-4"
        >
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.geschichteUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Geschichte"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Geschichte"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.geschichte')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.geschmackUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Geschmack"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Geschmack"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.geschmack')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.aboutSection.schokoUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Schokoladenherstellung"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Schokoladenherstellung"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.aboutSection.schoko')}
            </a>
          </span>
        </div>
      )
    }
    return (
      <div
        className="grid-2-m grid-m-12 grid-s-2-m footer__category js-accordion"
        data-target="footer-card-4"
      >
        <h4 className="js-footer-accordion" data-target="footer-card-4">
          <a
            className="footer__title js-event-cta footer-desktop"
            target="_blank"
            data-target="footer-card-4"
            rel="noopener noreferrer"
            href={t('footer.aboutSection.aboutUrl')}
            title="Über Milka"
            data-event="info-click"
            data-category="Informational Action"
            data-action="Footer Link"
            data-label="UBER MILKA"
            // onClick={appGoogleTracking.processEventCTA}
          >
            {t('footer.aboutSection.about')}
          </a>
          <a
            className="footer__title js-event-cta footer-mobile"
            data-target="footer-card-4"
          >
            {t('footer.aboutSection.about')}
          </a>
          <span
            className="footer__control js-footer-accordion-icon"
            data-target="footer-card-4"
          ></span>
        </h4>
        <hr />
        {footerAbout}
      </div>
    )
  }
}

export default withTranslation()(footerabout)
