import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import appGoogleTracking from "../../modules/GoogleTracking";

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

class footershop extends Component {
  render(){
    const {t} = this.props;
    return (
  <div
    className="grid-2-m grid-m-12 grid-s-2-m footer__category js-accordion"
    data-target="footer-card-2"
  >
    <h4 className="js-footer-accordion" data-target="footer-card-2">
      <a
        className="footer__title js-event-cta footer-desktop"
        data-target="footer-card-2"
        href={t("footer.shopSection.shopUrl")}
        target="_blank"
        rel="noopener noreferrer"
        data-event="info-click"
        data-category="Informational Action"
        data-action="Footer Link"
        data-label="SHOP"
        title="Shop"
        //onClick={appGoogleTracking.processEventCTA}
      >
        {t("footer.shopSection.shop")}
      </a>
      <a
        className="footer__title js-event-cta footer-mobile"
        data-target="footer-card-2"
      >
        {t("footer.shopSection.shop")}
      </a>
      <span
        className="footer__control js-footer-accordion-icon"
        data-target="footer-card-2"
      ></span>
    </h4>
    <hr />
    <div
      className="grid-column footer__links js-footer-accordion-content"
      data-target-child="footer-card-2"
    >
      <span className="footer__link">
        <a
          className="js-event-cta"
          href={t("footer.shopSection.schokoladenUrl")}
          target="_blank"
          rel="noopener noreferrer"
          title="Schokoladen Geschenke"
          data-event="info-click"
          data-category="Informational Action"
          data-action="Footer Link"
          data-label="Schokoladen Geschenke"
          //onClick={appGoogleTracking.processEventCTA}
        >
          {t("footer.shopSection.schokoladen")}
        </a>
      </span>
      <span className="footer__link">
        <a
          className="js-event-cta"
          href={t("footer.shopSection.personaliseUrl")}
          target="_blank"
          rel="noopener noreferrer"
          title="Personalisierte Schokolade"
          data-event="info-click"
          data-category="Informational Action"
          data-action="Footer Link"
          data-label="Personalisierte Schokolade"
          //onClick={appGoogleTracking.processEventCTA}
        >
          {t("footer.shopSection.personalise")}
        </a>
      </span>
      <span className="footer__link">
        <a
          className="js-event-cta"
          href={t("footer.shopSection.pralinenUrl")}
          target="_blank"
          rel="noopener noreferrer"
          title="Personalisierte Pralinen"
          data-event="info-click"
          data-category="Informational Action"
          data-action="Footer Link"
          data-label="Personalisierte Pralinen"
          //onClick={appGoogleTracking.processEventCTA}
        >
          {t("footer.shopSection.pralinen")}
        </a>
      </span>
    </div>
  </div>
    )
}
}
export default withTranslation()(footershop);
