import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import { mainLoaderToggle } from "../../modules/Helpers";
import appGoogleTracking from "../../modules/GoogleTracking";
import GLOBAL_CONFIG from "../../config/Config";
import Banner from "../../components/Oreo/Banner";

import { withRouter } from 'react-router-dom'
class End extends Component {
  componentDidMount() {
    mainLoaderToggle("hide");
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <body className={window.URL_PREFIX + " End"} />
        </Helmet>
        <Banner
            DesktopImage={t("page.end.oreo.banner.desktop")}
            MobileImage={t("page.end.oreo.banner.mobile")}
            AltText={t("page.end.oreo.banner.alt")}
        />
        <div className="endpage__wrapper">
            <p className="text__heading">{t('page.end.oreo.title')}</p>
            <p className="text__desc">{t('page.end.oreo.desc')}</p>

            <div className="product-wrapper__second">
                <div className="product-content">
                <div className="product-content__desc">
                    {t("page.holding.oreo.product.text-1")}
                </div>
                <img
                className="product__img"
                src={t("page.holding.oreo.product.4")}
                alt="product"
                />
                </div>
                <div className="product-content">
                <div className="product-content__desc">
                {t("page.holding.oreo.product.text-2")}
                </div>
                <img
                className="product__img"
                src={t("page.holding.oreo.product.5")}
                alt="product"
                />
                </div>
            </div>
            <div className="product-wrapper-mobile-second">
              <div className="product-content">
                <img
                className="product__img"
                src={t("page.holding.oreo.product.5")}
                alt="product"
                />
                <div className="product-content__title">
                  OREO Muffin
                </div>
                <div className="product-content__desc">
                {t("page.holding.oreo.product.text-2")}
                </div>
              </div>
              <div className="product-content">
                <img
                className="product__img"
                src={t("page.holding.oreo.product.4")}
                alt="product"
                />
                <div className="product-content__title">
                  OREO Donut
                </div>
                <div className="product-content__desc">
                  {t("page.holding.oreo.product.text-1")}
                </div>
              </div>
            </div>
            <a 
                href={t("page.holding.oreo.cta-link")}
                target="_blank" 
                className="oreo-button" 
                data-event="discover_oreo"
                data-category="End Page"
                data-action="Go to Page"
                data-label="OREO_EP_OTHER"
                onClick={appGoogleTracking.processEventCTA}
                rel="noreferrer"
                >
                <button className="oreo-button-blue">
                {t("page.holding.oreo.cta-text")}
                </button>
          </a>
        </div>
      </>
    );
  }
}

export default withTranslation()(End);