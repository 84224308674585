import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import { mainLoaderToggle } from "../../modules/Helpers";
import appGoogleTracking from "../../modules/GoogleTracking";
import GLOBAL_CONFIG from "../../config/Config";
import Banner from "../../components/Oreo/Banner";

class Holding extends Component {
  componentDidMount() {
    const { t } = this.props;
    mainLoaderToggle("hide");
  }
  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    const { t } = this.props;

    return (
      <>
        {/* Metadata */}
        <Helmet>
          <title>{t('meta.title')}</title>
          <meta name="description" content={t('meta.description')} />
          <body className={window.URL_PREFIX + " holding"} />
        </Helmet>
        <Banner
          DesktopImage = {t("page.holding.oreo.banner.desktop")}
          MobileImage = {t("page.holding.oreo.banner.mobile")}
          AltText = {t("page.holding.oreo.banner.alt")}
        />
        
{/*         <div className="banner-container">
          <picture>
            <source srcSet={t("page.holding.banner.desktop")} media="(min-width: 1024px)" />
            <source srcSet={t("page.holding.banner.desktop")} media="(min-width: 481px)" />
            <source srcSet={t("page.holding.banner.mobile")} media="(min-width: 200px)" />
            <img
              className="banner__image"
              src={t("page.holding.banner.desktop")}
              alt={t("page.holding.banner.alt")}
            />
          </picture>
        </div> */}
        <div className="wrapper oreo-holding">
          <div className="holding__content">
            <p className="text__heading">{t("page.holding.oreo.title")}</p>
            <p className="text__desc">{t("page.holding.oreo.desc")}</p>
          </div>

          <div className="product-wrapper">
            <div className="item">
              <picture>
                <source srcSet={t("page.holding.oreo.product.1")} media="(min-width: 481px)" />
                <source srcSet={t("page.holding.oreo.product.1-sm")} media="(min-width: 200px)" />
                <img
                  className="product__img"
                  src={t("page.holding.oreo.product.1")}
                  alt="product"
                />
              </picture>
              <p>{t("page.holding.oreo.product.product-similar")}</p>
            </div>
            <div className="item">
              <picture>
                <source srcSet={t("page.holding.oreo.product.2")} media="(min-width: 481px)" />
                <source srcSet={t("page.holding.oreo.product.2-sm")} media="(min-width: 200px)" />
                <img
                  className="product__img"
                  src={t("page.holding.oreo.product.2")}
                  alt="product"
                />
              </picture>
              <p>{t("page.holding.oreo.product.product-similar")}</p>
            </div>
            <div className="item">
            <picture>
                <source srcSet={t("page.holding.oreo.product.3")} media="(min-width: 481px)" />
                <source srcSet={t("page.holding.oreo.product.3-sm")} media="(min-width: 200px)" />
                <img
                  className="product__img"
                  src={t("page.holding.oreo.product.3")}
                  alt="product"
                />
              </picture>
              <p>{t("page.holding.oreo.product.product-similar")}</p>
            </div>
          </div>
          <div className="product-text"><p>{t("page.holding.oreo.product.product-similar")}</p></div>
          <div className="product-wrapper__second">
            <div className="product-content">
              <div className="product-content__desc">
                {t("page.holding.oreo.product.text-1")}
              </div>
              <img
              className="product__img"
              src={t("page.holding.oreo.product.4")}
              alt="product"
              />
            </div>
            <div className="product-content">
              <div className="product-content__desc">
              {t("page.holding.oreo.product.text-2")}
              </div>
              <img
              className="product__img"
              src={t("page.holding.oreo.product.5")}
              alt="product"
              />
            </div>
          </div>

{/*           <div className="product-wrapper-mobile">
              <p>
              {parse(t("page.holding.oreo.product.text-mobile"))}
              </p>
          </div> */}

{/*           <div className="product-wrapper-mobile-second">
              <div className="product-content">
                <img
                className="product__img"
                src={t("page.holding.oreo.product.4-sm")}
                alt="product"
                />
              </div>
              <div className="product-content">
                <img
                className="product__img"
                src={t("page.holding.oreo.product.5-sm")}
                alt="product"
                />
              </div>
          </div> */}
          <div className="product-wrapper-mobile-second">
              <div className="product-content">
                <img
                className="product__img"
                src={t("page.holding.oreo.product.5")}
                alt="product"
                />
                <div className="product-content__title">
                  OREO Muffin
                </div>
                <div className="product-content__desc">
                {t("page.holding.oreo.product.text-2")}
                </div>
              </div>
              <div className="product-content">
                <img
                className="product__img"
                src={t("page.holding.oreo.product.4")}
                alt="product"
                />
                <div className="product-content__title">
                  OREO Donut
                </div>
                <div className="product-content__desc">
                  {t("page.holding.oreo.product.text-1")}
                </div>
              </div>
          </div>

          <a 
          href={t("page.holding.oreo.cta-link")}
          target="_blank" 
          className="oreo-button" 
          data-event="discover_oreo"
          data-category="Announcement Page"
          data-action="Go to Page"
          data-label="OREO_AP_OTHER"
          onClick={appGoogleTracking.processEventCTA}
          rel="noreferrer"
          >
            <button className="oreo-button-blue">
            {t("page.holding.oreo.cta-text")}
            </button>
          </a>
        </div>
      </>
    );
  }
}

export default withTranslation()(Holding);
