import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import appGoogleTracking from '../../modules/GoogleTracking';
import StickyHeader from '../../modules/StickyHeader';
import HamburgerMenu from '../../modules/HamburgerMenu';

class HeaderOreo extends Component {
  componentDidMount() {
      new StickyHeader({
        headerSelector: '.js-header.header-md__container',
        contentSelector: '.js-header-md-content',
        iconSelector: '.js-header-md-hamburger',
        linkSelector: '.header-md__navigation',
      })

      new StickyHeader({
        headerSelector: '.js-header.header__container .header__head',
        linkSelector: '.header__navigation',
      })

      new HamburgerMenu({
        menuSelector: '.js-hamburger',
        contentSelector: '.js-ham-content',
        modifier: 'header__menu--open',
        close: '.js-ham-close',
      })
  }

  render() {
    const { t } = this.props
    let navDesktop = null,
      navMobile = null,
      navFlag = null,
      flag1 = "",
      urlflag1 = "",
      flag2 = "",
      urlflag2 = "",
      youtubeIcon = null;

      let newsletterLink = (
        <li>
          <a
            className="header-md__navigation js-event-cta"
            href={t('header.newsletterOreoUrl')}
            target="_blank"
            rel="noopener noreferrer"
            data-event="header_menu"
            data-category="Header"
            data-action="Select Menu"
            data-label="Newsletter"
            onClick={appGoogleTracking.processEventCTA}
          >
            {t('header.newsletterOreo')}
          </a>
        </li>
      );
      let newsletterLinkMobile = (
        <li>
          <a
            className="header__navigation js-event-cta"
            target="_blank"
            rel="noopener noreferrer"
            href={t('header.newsletterOreoUrl')}
            data-event="header_menu"
            data-category="Header"
            data-action="Select Menu"
            data-label="Newsletter"
            onClick={appGoogleTracking.processEventCTA}
          >
            {t('header.newsletterOreo')}
          </a>
        </li>
      );
      let faqLinkMobile = (
        <li>
          <a
            className="header__navigation js-event-cta"
            target="_blank"
            rel="noopener noreferrer"
            href={t('header.faqsUrl')}
            data-event="header_menu"
            data-category="Header"
            data-action="Select Menu"
            data-label="FAQS"
            onClick={appGoogleTracking.processEventCTA}
          >
            {t('header.faqs')}
          </a>
        </li>
      );
      let faqLink = (
        <li>
          <a
            className="header-md__navigation js-event-cta"
            data-event="header_menu"
            data-category="Header"
            data-action="Select Menu"
            data-label="FAQS"
            href={t('header.faqsUrl')}
            target="_blank"
            rel="noopener noreferrer"
            onClick={appGoogleTracking.processEventCTA}
          >
            {t('header.faqs')}
          </a>
        </li>
      );
      let twitterIcon = (
        <li className="header-md__icon header-md__country">
          <a
            href={t('header.oreoTwitter')}
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_social_media"
            data-category="Header"
            data-action="Go to Page"
            data-label="Twitter"
            onClick={appGoogleTracking.processEventCTA}
          >
            <img
              src="/resources/images/oreo/twitter.png"
              alt="Oreo twitter"
            />
          </a>
        </li>
      )
      let tiktokIcon = (
        <li className="header-md__icon header-md__contact">
          <a
            className="js-event-cta"
            href={t('header.oreoTiktok')}
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_social_media"
            data-category="Header"
            data-action="Go to Page"
            data-label="Tiktok"
            onClick={appGoogleTracking.processEventCTA}
          >
            <img
              src="/resources/images/oreo/tiktok.png"
              alt="Oreo tiktok"
            />
          </a>
        </li>
      )

    if(
      window.URL_PREFIX === 'de-de' || 
      window.URL_PREFIX === 'de-at' ||
      window.URL_PREFIX === 'de-ch' ||
      window.URL_PREFIX === 'lb-lu' 
      ) {
      newsletterLink = null;
      newsletterLinkMobile = null;
      twitterIcon = null;
      youtubeIcon = (
        <li className="header-md__icon header-md__country">
          <a
            href="https://protect-eu.mimecast.com/s/JueICPMvNT5QY9LqtzggyT?domain=youtube.com"
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_social_media"
            data-category="Header"
            data-action="Go to Page"
            data-label="Youtube"
            onClick={appGoogleTracking.processEventCTA}
          >
            <img
              src="/resources/images/oreo/youtube.png"
              alt="Oreo Youtube"
            />
          </a>
        </li>
      )
    }
    if (
      window.URL_PREFIX === 'fr-fr' ||
      window.URL_PREFIX === 'fr-ch' ||
      window.URL_PREFIX === 'fr-be' ||
      window.URL_PREFIX === 'fr-lu'
    ) {
      faqLink= null;
      faqLinkMobile= null;
      newsletterLink = null;
      newsletterLinkMobile = null;
      twitterIcon = null;
      tiktokIcon = null;
    }
    if (
      window.URL_PREFIX === 'nl-nl' ||
      window.URL_PREFIX === 'nl-be' 
    ) {
      twitterIcon = null;
      tiktokIcon = null;
    }

    if (this.props.urlPrefix === 'de-ch') {
      flag1 = 'french'; urlflag1 = '/fr-ch'; flag2 = 'german-unactive'; urlflag2 = '/de-ch';
    }else if (this.props.urlPrefix === 'fr-ch') {
      flag1 = 'german'; urlflag1 = '/de-ch'; flag2 = 'french-unactive'; urlflag2 = '/fr-ch';
    }else if (this.props.urlPrefix === 'nl-be') {
      flag1 = 'french'; urlflag1 = '/fr-be'; flag2 = 'dutsch-unactive'; urlflag2 = '/nl-be';
    }else if (this.props.urlPrefix === 'fr-be') {
      flag1 = 'dutsch'; urlflag1 = '/nl-be'; flag2 = 'french-unactive'; urlflag2 = '/fr-be';
    }else if (this.props.urlPrefix === 'lb-lu') {
      flag1 = 'french'; urlflag1 = '/fr-lu'; flag2 = 'german-unactive'; urlflag2 = '/lb-lu';
    }else if (this.props.urlPrefix === 'fr-lu') {
      flag1 = 'german'; urlflag1 = '/lb-lu'; flag2 = 'french-unactive'; urlflag2 = '/fr-lu';
    }
    
    if(!!flag2) {
      console.log("🚀 ~ file: Header.jsx:63 ~ HeaderOreo ~ render ~ flag2:", flag2)
      navFlag = (
        <ul className="header-md__navigations">
          <li className="header-md__icon header-md__flag">
            <a
              href={urlflag1}
              rel="noopener noreferrer"
              data-event="language_option"
              data-category="Header"
              data-action="Select Language"
              data-label={flag1}
              onClick={appGoogleTracking.processEventCTA}
            >
              <img 
                src={'/resources/images/oreo/flag/'+flag1+'.png'} 
                alt="Flag" 
              />
            </a>
          </li>
          <li className="header-md__icon header-md__flag">
            <a
              href={urlflag2}
              rel="noopener noreferrer"
              data-event="language_option"
              data-category="Header"
              data-action="Select Language"
              data-label={flag2}
              onClick={appGoogleTracking.processEventCTA}
            >
              <img 
                src={'/resources/images/oreo/flag/'+flag2+'.png'} 
                alt="Flag" 
              />
            </a>
          </li>
        </ul>
      )
    }

    //nav by country
    
      navMobile = (
        <div className="header__navigations">
          <ul>
            <li>
              <a
                className="header__navigation js-event-cta"
                href={t('header.homeUrl')}
                target="_blank"
                rel="noopener noreferrer"
                data-event="header_menu"
                data-category="Header"
                data-action="Select Menu"
                data-label="Home"
                onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.home')}
              </a>
            </li>
            <li>
              <a
                className="header__navigation js-event-cta"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.productOreoUrl')}
                data-event="header_menu"
                data-category="Header"
                data-action="Select Menu"
                data-label="Products"
                onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.productOreo')}
              </a>
            </li>
            <li>
              <a
                className="header__navigation js-event-cta"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.recipesUrl')}
                data-event="header_menu"
                data-category="Header"
                data-action="Select Menu"
                data-label="Recipes"
                onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.recipes')}
              </a>
            </li>
{/*             <li>
              <a
                className="header__navigation js-event-cta"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.faqsUrl')}
                data-event="header_menu"
                data-category="Header"
                data-action="Select Menu"
                data-label="FAQS"
                onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.faqs')}
              </a>
            </li> */}
            {faqLinkMobile}
{/*             <li>
              <a
                className="header__navigation js-event-cta"
                target="_blank"
                rel="noopener noreferrer"
                href={t('header.newsletterOreoUrl')}
                data-event="header_menu"
                data-category="Header"
                data-action="Select Menu"
                data-label="Newsletter"
                onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.newsletterOreo')}
              </a>
            </li> */}

            {newsletterLinkMobile}
          </ul>
        </div>
      )
      navDesktop = (
        <div className="js-header-md-content header-md__content">
          <ul className="header-md__navigations">
            <li>
              <a
                className="header-md__navigation js-event-cta underline"
                data-event="header_menu"
                data-category="Header"
                data-action="Select Menu"
                data-label="Home"
                href={t('header.homeUrl')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.home')}
              </a>
            </li>
            <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="header_menu"
                data-category="Header"
                data-action="Select Menu"
                data-label="Products"
                href={t('header.productOreoUrl')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.productOreo')}
              </a>
            </li>
            <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="header_menu"
                data-category="Header"
                data-action="Select Menu"
                data-label="Recipes"
                href={t('header.recipesUrl')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.recipes')}
              </a>
            </li>
           {/* <li>
              <a
                className="header-md__navigation js-event-cta"
                data-event="header_menu"
                data-category="Header"
                data-action="Select Menu"
                data-label="FAQS"
                href={t('header.faqsUrl')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.faqs')}
              </a>
            </li> */}
            {faqLink}
{/*             <li>
              <a
                className="header-md__navigation js-event-cta"
                href={t('header.newsletterOreoUrl')}
                target="_blank"
                rel="noopener noreferrer"
                data-event="header_menu"
                data-category="Header"
                data-action="Select Menu"
                data-label="Newsletter"
                onClick={appGoogleTracking.processEventCTA}
              >
                {t('header.newsletterOreo')}
              </a>
            </li> */}
            {newsletterLink}
            { /* navFlag */ }
            { (window.URL_PREFIX !== 'fr-fr') && navFlag }
          </ul>
          <ul className="header-md__icons">
            <li className="header-md__icon header-md__country">
              <a
                data-event="go_to_social_media"
                data-category="Header"
                data-action="Go to Page"
                data-label="Facebook"
                onClick={appGoogleTracking.processEventCTA}
                href={t('header.oreoFacebook')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/resources/images/oreo/facebook.png"
                  alt="Oreo facebook"
                />
              </a>
            </li>
            <li className="header-md__icon header-md__country">
              <a
                href={t('header.oreoInstagram')}
                target="_blank"
                rel="noopener noreferrer"
                data-event="go_to_social_media"
                data-category="Header"
                data-action="Go to Page"
                data-label="Instagram"
                onClick={appGoogleTracking.processEventCTA}
              >
                <img
                  src="/resources/images/oreo/instagram.png"
                  alt="Oreo instagram"
                />
              </a>
            </li>
{/*             <li className="header-md__icon header-md__country">
              <a
                href={t('header.oreoTwitter')}
                target="_blank"
                rel="noopener noreferrer"
                data-event="go_to_social_media"
                data-category="Header"
                data-action="Go to Page"
                data-label="Twitter"
                onClick={appGoogleTracking.processEventCTA}
              >
                <img
                  src="/resources/images/oreo/twitter.png"
                  alt="Oreo twitter"
                />
              </a>
            </li> */}
            {twitterIcon}
{/*             <li className="header-md__icon header-md__contact">
              <a
                className="js-event-cta"
                href={t('header.oreoTiktok')}
                target="_blank"
                rel="noopener noreferrer"
                data-event="go_to_social_media"
                data-category="Header"
                data-action="Go to Page"
                data-label="Tiktok"
                onClick={appGoogleTracking.processEventCTA}
              >
                <img
                  src="/resources/images/oreo/tiktok.png"
                  alt="Oreo tiktok"
                />
              </a>
            </li> */}
            {tiktokIcon}
            {youtubeIcon}
          </ul>
        </div>
      )

    return (
      <>
        <header>
          {/* mobile */}
          <nav className="js-header header__container oreo-header-container">
            <div className="header__head oreo-header-mob">
              <div className="js-hamburger header__menu" id="hamburger-m">
                <span className="menu-line"></span>
                <span className="menu-line"></span>{' '}
                <span className="menu-line"></span>
                <span className="menu-line"></span>
              </div>
              <div className="header__logo">
                <a href={t('header.homeUrl')}  rel="noopener noreferrer">
                <img
                  src="/resources/images/oreo/oreo-logo.png"
                  alt="Oreo logo"
                />
                </a>
              </div>
            </div>
            <div className="header__content js-ham-content">
              <div className="header__head">
                <div className="js-ham-close">
                <img class="js-ham-close header-menu-navigations__close" src="/resources/images/oreo/ham-close.png" alt="Hamburger Close"/>
                </div>
              </div>
              {navMobile}
            </div>
          </nav>
          {/* Desktop */}
          <nav className="js-header header-md__container oreo-header">
            <div className="header-md__logo">
              <a
                className="nav__item js-event-cta"
                data-event="go_to_oreo"
                data-category="Header"
                data-action="Go to Page"
                data-label="OREO_ALLPAGE_NAVBAR"
                href={t('header.homeUrl')} 
                rel="noopener noreferrer"
                onClick={appGoogleTracking.processEventCTA}
              >
                <img
                  src="/resources/images/oreo/oreo-logo.png"
                  alt="Oreo logo"
                />
              </a>
            </div>
            <div className="js-header-md-hamburger header-md__menu">
              <div className="header__btn" id="hamburger">
                <span className="menu-line"></span>
                <span className="menu-line"></span>
                <span className="menu-line"></span>
                <span className="menu-line"></span>
              </div>
            </div>
            {navDesktop}
          </nav>
        </header>
      </>
    )
  }
}

export default withTranslation()(HeaderOreo)
