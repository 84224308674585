import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import appGoogleTracking from '../../modules/GoogleTracking'

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

class footernews extends Component {
  render() {
    const { t } = this.props
    let footerNews = null

    if (window.URL_PREFIX === 'de-de' || window.URL_PREFIX === 'de-at') {
      footerNews = (
        <div
          className="grid-column footer__links js-footer-accordion-content"
          data-target-child="footer-card-3"
        >
          {/* <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.newsSection.zarteBotschaftUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Zarte Botschaft"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Zarte Botschaft"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.newsSection.zarteBotschaft")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.newsSection.herzenswünscheUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Herzenswünsche"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Herzenswünsche"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.newsSection.herzenswünsche")}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t("footer.newsSection.haselUrl")}
              target="_blank"
              rel="noopener noreferrer"
              title="Haselnusscreme"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Haselnusscreme"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t("footer.newsSection.hasel")}
            </a>
          </span> */}
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.newsSection.zarteUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Zarte Botschaft"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Zarte Botschaft"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.newsSection.zarte')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.newsSection.haselUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Haselnusscreme"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Haselnusscreme"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.newsSection.hasel')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.newsSection.darkUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Dark Milk"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Dark Milk"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.newsSection.dark')}
            </a>
          </span>
        </div>
      )
    } else if (window.URL_PREFIX === 'de-at') {
      footerNews = (
        <div
          className="grid-column footer__links js-footer-accordion-content"
          data-target-child="footer-card-3"
        >
          <span className="footer__link field-link">
            <a
              className="js-event-cta"
              href="#"
              title="Mmmax Strawberry Cheesecake"
              data-variantfieldname="Link"
              target="_blank"
              rel="noopener noreferrer"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Mmmax Strawberry Cheesecake"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.newsSection.mmmax')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.newsSection.zarteUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Zarte Botschaft"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Zarte Botschaft"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.newsSection.zarte')}
            </a>
          </span>
          <span className="footer__link">
            <a
              className="js-event-cta"
              href={t('footer.newsSection.darkUrl')}
              target="_blank"
              rel="noopener noreferrer"
              title="Dark Milk"
              data-event="info-click"
              data-category="Informational Action"
              data-action="Footer Link"
              data-label="Dark Milk"
              // onClick={appGoogleTracking.processEventCTA}
            >
              {t('footer.newsSection.dark')}
            </a>
          </span>
        </div>
      )
    }
    return (
      <div
        className="grid-2-m grid-m-12 grid-s-2-m footer__category js-accordion"
        data-target="footer-card-3"
      >
        <h4 className="js-footer-accordion" data-target="footer-card-3">
          <a
            className="footer__title footer-desktop"
            href={t('footer.newsSection.newsUrl')}
            data-target="footer-card-3"
            target="_blank"
            rel="noopener noreferrer"
            title="Neues"
            data-event="info-click"
            data-category="Informational Action"
            data-action="Footer Link"
            data-label="NEUES"
            // onClick={appGoogleTracking.processEventCTA}
          >
            {t('footer.newsSection.news')}
          </a>
          <a
            className="footer__title footer-mobile"
            data-target="footer-card-3"
          >
            {t('footer.newsSection.news')}
          </a>
          <span
            className="footer__control js-footer-accordion-icon"
            data-target="footer-card-3"
          ></span>
        </h4>
        <hr />
        {footerNews}
      </div>
    )
  }
}

export default withTranslation()(footernews)
