import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import parse from "html-react-parser";
import Scrolltop from '../../modules/ScrollTop';
import appGoogleTracking from '../../modules/GoogleTracking';
import GLOBAL_CONFIG, { GetRoute } from '../../config/Config';


class FooterOreo extends Component {
  componentDidMount() {
      new Scrolltop({
        selector: '.js-scroll-top',
        heightActive: 600,
      })
      console.log("status",window.PROMOTION);
  }

  render() {
    const { t } = this.props;
    let Routes = GetRoute(window.URL_PREFIX); 
    let youtubeIcon = null;
    let twitterIcon = (
      <li className="footer-md__icon">
        <a
          href={t('header.oreoTwitter')}
          target="_blank"
          rel="noopener noreferrer"
          data-event="go_to_social_media"
          data-category="Footer"
          data-action="Go to Page"
          data-label="Twitter"
          onClick={appGoogleTracking.processEventCTA}
        >
          <img
            src="/resources/images/oreo/twitter.png"
            alt="Oreo twitter"
          />
        </a>
      </li>
    )
    let tiktokIcon = (
      <li className="footer-md__icon">
        <a
          className="js-event-cta"
          href={t('header.oreoTiktok')}
          target="_blank"
          rel="noopener noreferrer"
          data-event="go_to_social_media"
          data-category="Footer"
          data-action="Go to Page"
          data-label="Tiktok"
          onClick={appGoogleTracking.processEventCTA}
        >
          <img
            src="/resources/images/oreo/tiktok.png"
            alt="Oreo tiktok"
          />
        </a>
      </li>
    )

    if (
      window.URL_PREFIX === 'de-de' || 
      window.URL_PREFIX === 'de-at' ||
      window.URL_PREFIX === 'de-ch' ||
      window.URL_PREFIX === 'lb-lu' 
    ) {
      twitterIcon = null;
      youtubeIcon = (
        <li className="footer-md__icon">
          <a
            href="https://protect-eu.mimecast.com/s/JueICPMvNT5QY9LqtzggyT?domain=youtube.com"
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_social_media"
            data-category="Footer"
            data-action="Go to Page"
            data-label="Youtube"
            onClick={appGoogleTracking.processEventCTA}
          >
            <img
              src="/resources/images/oreo/youtube.png"
              alt="Oreo Youtube"
            />
          </a>
        </li>
      )
    }

    if (
      window.URL_PREFIX === 'fr-fr' ||
      window.URL_PREFIX === 'fr-ch' ||
      window.URL_PREFIX === 'fr-be' ||
      window.URL_PREFIX === 'fr-lu'
    ) {
      tiktokIcon = null;
      twitterIcon = null;
    }

    if (
      window.URL_PREFIX === 'nl-nl' ||
      window.URL_PREFIX === 'nl-be' 
    ) {
      tiktokIcon = null;
      twitterIcon = null;
    }

    let links = (
      <>
          <a 
          href={t('footer.oreo.cookieUrl')} 
          target='_blank' 
          rel="noreferrer" 
          className="footer-links"
          data-event="footer_menu"
          data-category="Footer"
          data-action="Select Menu"
          data-label="Cookies"
          onClick={appGoogleTracking.processEventCTA}>
            {t('footer.oreo.cookie')}
          </a>

          <a 
          href={t('footer.oreo.companyInfoUrl')} 
          target='_blank' 
          rel="noreferrer" 
          className="footer-links"
          data-event="footer_menu"
          data-category="Footer"
          data-action="Select Menu"
          data-label="Company info"
          onClick={appGoogleTracking.processEventCTA}>
            {t('footer.oreo.companyInfo')}
          </a>

          {
            window.PROMOTION === "end" ? null : <a 
            href={`/${window.URL_PREFIX}${Routes.termsAndConditions}`} 
            target='_blank' 
            rel="noreferrer" 
            className="footer-links"
            data-event="footer_menu"
            data-category="Footer"
            data-action="Select Menu"
            data-label="Terms and Conditions"
            onClick={appGoogleTracking.processEventCTA}>
              {t('footer.oreo.termsAndCond')}
            </a>
          }
          

          <a 
          href={t('footer.oreo.termsOfUseUrl')} 
          target='_blank' 
          rel="noreferrer" 
          className="footer-links"
          data-event="footer_menu"
          data-category="Footer"
          data-action="Select Menu"
          data-label="Terms of use"
          onClick={appGoogleTracking.processEventCTA}>
            {t('footer.oreo.termsOfUse')}
          </a>

          <a 
          href={t('footer.oreo.privacyNoticeUrl')} 
          target='_blank' 
          rel="noreferrer" 
          className="footer-links"
          data-event="footer_menu"
          data-category="Footer"
          data-action="Select Menu"
          data-label="Privacy notice"
          onClick={appGoogleTracking.processEventCTA}>
            {t('footer.oreo.privacyNotice')}
          </a>

          <a 
          href={t('footer.oreo.contactUsUrl')} 
          target='_blank' 
          rel="noreferrer" 
          className="footer-links"
          data-event="footer_menu"
          data-category="Footer"
          data-action="Select Menu"
          data-label="Contact us"
          onClick={appGoogleTracking.processEventCTA}>
            {t('footer.oreo.contactUs')}
          </a>

          <a 
          href={t('footer.oreo.corporateReportUrl')} 
          target='_blank' 
          rel="noreferrer" 
          className="footer-links"
          data-event="footer_menu"
          data-category="Footer"
          data-action="Select Menu"
          data-label="Corporate reporting"
          onClick={appGoogleTracking.processEventCTA}>
            {t('footer.oreo.corporateReport')}
          </a>
      </>
    );
    let social_icon = (
      <ul className="footer-icon">
        <li className="footer-md__icon">
          <a
            href={t('header.oreoFacebook')}
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_social_media"
            data-category="Footer"
            data-action="Go to Page"
            data-label="Facebook"
            onClick={appGoogleTracking.processEventCTA}
          >
            <img
              src="/resources/images/oreo/facebook.png"
              alt="Oreo facebook"
            />
          </a>
        </li>
        <li className="footer-md__icon">
          <a
            href={t('header.oreoInstagram')}
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_social_media"
            data-category="Footer"
            data-action="Go to Page"
            data-label="Instagram"
            onClick={appGoogleTracking.processEventCTA}
          >
            <img
              src="/resources/images/oreo/instagram.png"
              alt="Oreo instagram"
            />
          </a>
        </li>
        {/* <li className="footer-md__icon">
          <a
            href={t('header.oreoTwitter')}
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_social_media"
            data-category="Footer"
            data-action="Go to Page"
            data-label="Twitter"
            onClick={appGoogleTracking.processEventCTA}
          >
            <img
              src="/resources/images/oreo/twitter.png"
              alt="Oreo twitter"
            />
          </a>
        </li> */}
        {twitterIcon}
        {tiktokIcon}
        {youtubeIcon}
        {/* <li className="footer-md__icon">
          <a
            className="js-event-cta"
            href={t('header.oreoTiktok')}
            target="_blank"
            rel="noopener noreferrer"
            data-event="go_to_social_media"
            data-category="Footer"
            data-action="Go to Page"
            data-label="Tiktok"
            onClick={appGoogleTracking.processEventCTA}
          >
            <img
              src="/resources/images/oreo/tiktok.png"
              alt="Oreo tiktok"
            />
          </a>
        </li> */}
      </ul>
    )
    
    return (
      <div className="oreo-footer-wrapper">
        <div 
        className="footer__top js-scroll-top"
        data-event="scroll_up_button"
        data-category="Footer"
        data-action="Click on Scroll Up"
        data-label="OREO_ALLPAGE_FOOTER"
        onClick={appGoogleTracking.processEventCTA}>
          <img
            src="/resources/images/oreo/up.png"
            alt="To Top"
          />
          UP
        </div>
        <div className="oreo-footer-container">
            {social_icon}         
            {links}
        </div>
        <div className="oreo-footer-text">
          {t('footer.oreo.copyright')}
        </div>
        <div className="oreo-footer-logo">
          <a 
          href='https://www.mondelezinternational.com/europe' 
          target='_blank' 
          rel="noreferrer" 
          data-event="go_to_mondelez"
          data-category="Footer"
          data-action="Go to Page"
          data-label="ENG_ALLPAGE_FOOTER"
          onClick={appGoogleTracking.processEventCTA}
          >
            <img
            className="footer-logo"
            src="/resources/images/oreo/mdlz-logo-snacking-purple.png"
            alt="footer-logo"
            />
          </a>
        </div>
      </div>
        
    )
  }
}
export default withTranslation()(FooterOreo)

