import React from 'react';

const Loader = ( props ) => {
    return (
        <div className="simple-loader__container active">
            <div className="simple-loader__indicator"></div>
        </div>
    );
}

export default Loader;