import axios from 'axios'
import { ServicesUrl } from '../modules/Helpers'
const participation = () => {
  const _URL = '/Participation/SubmitParticipation'

  const _NEWSLETTER = '/api/participation/NewsletterSubscribe'

  const _SERVICE_URL = ServicesUrl();

  const Participate = (_data) => {
    return axios({
      method: 'POST',
      url: _URL,
      data: _data,
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    })
  }
  const participateOreo = (_data) => {
    return axios({
      method: 'POST',
      url: `${_SERVICE_URL}/Participation/SubmitParticipation`,
      data: _data,
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    })
  }

  const newsletterSubscribe = (_data) => {
    return axios({
      method: 'POST',
      url: `${_NEWSLETTER}`,
      data: _data,
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    })
  }

  const supportingProfile = (_data) => {
    return axios({
      method: 'post',
      url: `https://milka-supporting.azurewebsites.net/supporting/crm/profile`,
      data: _data,
    })
  }

  return {
    Participate: Participate,
    ParticipateOreo: participateOreo,
    SupportingProfile: supportingProfile,
    NewsletterSubscribe: newsletterSubscribe,
  }
}

var ParticipationServices = participation()

export default ParticipationServices
